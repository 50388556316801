import React from "react";

function Impressum(props) {
    return (
        <div>

        </div>
    );
}

export default Impressum;