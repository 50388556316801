import React, {Component} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import Home from './HomeComponent';
import Leistungen from './LeistungComponent';
import Team from './TeamComponent';
import Diagnostic from './DiagnosticComponent';
import Physiotherapie from './PhysioTherapieComponent';
import Kontakt from './KontaktComponent';
import Navbar from './NavbarComponent';
import Data from '../assets/data/database'
import Info from "./InformationComponent";
import Datenschutz from "./DatenschutzComponent";
import Impressum from "./ImpressumComponent";
import Footer from "./FooterComponent";
import Radiologie from "./RadiologieComponent";
import Stosswellentherapie from './StosswellentherapieComponent';
import Ortho from './OrthoComponent'
import CovidNews from './news/CovidNewsComponent';

class Main extends Component {
    database = Data;

    render() {
        return (
            <div id="content">
                <Navbar/>
                <div>
                    <Switch location={this.props.location}>
                        <Route path='/home' component={Home}/>
                        <Route path='/leistungen' component={() => <Leistungen doctors={this.database.doctors}/>}/>
                        <Route path='/diagnostic' component={(location) => <Diagnostic fields={this.database.fields[0]} location={location}/>}/><Route path='/radiologie' component={() => <Radiologie data={this.database}/>}/>
                        <Route path='/physiotherapie' component={() => <Physiotherapie data={this.database}/>} fields={this.database.fields[1]} doctors={this.database.doctors}/>
                        <Route path='/team' component={() => <Team data={this.database}/>}/>
                        <Route path='/info' component={Info}/>
                        <Route path='/kontakt' component={Kontakt}/>
                        <Route path='/datenschutz' component={Datenschutz}/>
                        <Route path={'/impressum'} component={Impressum}/>
                        <Route path={'/covidnews'} component={CovidNews}/>
                        <Route path={'/stosswelle'} component={() => <Stosswellentherapie data={this.database}/>}/>
                        <Route path={'/ortho'} component={() => <Ortho data={this.database}/>}/>
                        <Redirect to="/home"/>
                    </Switch>
                </div>
                <Footer doctors={this.database.doctors}/>
            </div>
        );
    }
}

export default Main;