import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Map from "./MapComponent";
import {FaPhone} from "react-icons/fa";
import {IoIosMail} from "react-icons/io";

const CarouselComponent = (props) => {
    return (
        <Carousel className="carousel-fade" controls={true} indicators={true}>
            <Carousel.Item className="carousel__img--container">
                <div className="d-block w-100 carousel__img--item background__image--entry">
                </div>

            </Carousel.Item>
            <Carousel.Item className="carousel__img--container ">
                <div
                    className="d-block w-100 carousel__img--item background__image--physio">
                </div>

            </Carousel.Item>
            <Carousel.Item className="carousel__img--container">
                <div
                    className="d-block w-100 carousel__img--item background__image--therapy">
                </div>

            </Carousel.Item>
        </Carousel>
    );
};



function Kontakt(props) {
    return (
        <div>
            <CarouselComponent/>
            <div className={"home__map--container"}>
                <div className={"home__map--item map"}>
                    <Map/>
                </div>

                <div className={"home__map--item information__container"}>
                    <div className={"home__map--inside"}>
                        <h1 className={"home__map--header"}>Orthozentrum Wien</h1>
                        <p>Zentrum für Orthopädie <br/> Münichreiterstrasse 29/3 <br/> 1130 Wien</p>
                    </div>
                    <div className={"home__map--inside"}>
                        <h1 className={"home__map--header"}>Terminvereinbarung</h1>
                        <p><FaPhone className="info__icon"/> Tel: <a href="tel:+4318902808">+43 1 8902808</a></p>
                        <p><IoIosMail className="info__icon"/>Mail: <a href="mailto:office@orthozentrum.wien">office@orthozentrum.wien</a>  </p>
                    </div>
                    <div className={"home__map--inside"}>
                        <h1 className={"home__map--header"}>Anfahrt</h1>
                        <p>Über <span className={"home__map--highlight"}>U4 Hietzing</span> mit <span className={"home__map--highlight"}>58a/b</span> oder <span className={"home__map--highlight"}>56a/b</span> an <span className={"home__map--highlight"}>Stranzenberggasse</span> oder <span className={"home__map--highlight"}>Montecuccoliplatz</span><br/>
                            oder mit <span className={"home__map--highlight"}>62 Straßenbahn</span> an <span
                                className={"home__map--highlight"}>Atzgersdorfer Straße</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Kontakt;