import React from "react";
import {Link} from "react-router-dom";

const renderDoctors = (field, doctors) => {
    const baseUrl = "/images/";

    const activeDoctors = doctors.map(doctor => {
        if (doctor.fields.includes(field)) {
            return (
                <div className={"service__doctor--item"} key={doctor.id}>
                    <div className={""}>
                        <img className="service_image" src={baseUrl + doctor.image} alt={doctor.name}/>
                        <h3 className="service_subHead center">{doctor.title} {doctor.name}</h3>
                        <Link to={{
                            pathname: "/info",
                            state: {
                                doctor: doctor
                            }
                        }}>
                            <div className="service__doctor--link service_link">Mehr Information</div>
                        </Link>
                    </div>
                </div>
            )
        }else{
            return(<div key={doctor.id}></div>)
        }
    });

    return activeDoctors;
};

function Leistungen(props) {
    return (
        <div>
            <div className="banner__container">
                <div className="banner__text--container">
                    <span className="banner__info--text">Services</span>
                </div>
                <div className={"banner__parallax service__parallax"}>
                </div>
            </div>

            <div className="container-wrapper">
                {/*<Link to={"/orthopädie"}> <DropdownItem>Orthopädie</DropdownItem></Link>*/}
                <h1 className="service_head">Unsere Leistungen für Sie</h1>

                <div className="container-fluid service_container service_first">
                    <div className="row">
                        <div className="col">
                            <Link to={"/orthopädie"}><h2 className="service_subHead">Orthopädie</h2></Link>
                        </div>
                    </div>

                    <div className={"service__container"}>
                        <div className="service_description">
                            Die Orthopädie ist eine medizinische Teildisziplin der Chirurgie, die sich mit den Erkrankungen des Bewegungsapparats beschäftigt. Dazu zählen Gesundheitsstörungen der Knochen, Gelenke, Muskeln oder Sehnen. Die Orthopädie wird von Fachärzten für Orthopädie und Unfallchirurgie betrieben, die auch kurz Orthopäden genannt werden.
                        </div>
                        <div className={"service__link--container"}>
                            <Link className="service_link" to={"/orthopädie"}>Mehr Information</Link>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <h2 className="service_subHead">Unsere Orthopäden</h2>
                        </div>
                    </div>
                    <div className={"service__doctor--container"}>
                        {renderDoctors("Orthopädie", props.doctors)}
                    </div>
                </div>

                <div className="container-fluid service_container">
                    <div className="row">
                        <div className="col">
                            <Link to={"/physiotherapie"}><h2 className="service_subHead">Physiotherapie</h2></Link>
                        </div>
                    </div>

                    <div className={"service__container"}>
                        {/*<img className="service__image" src={require("../../assets/thumbs/physioPreview400x400.jpg")}*/}
                        {/*alt={"ortho"}/>*/}
                        <div className="service_description">
                            Jede Erkrankung und Verletzung des Bewegungsapparates bedarf einer genauen medizinischen Diagnose, Behandlung und Therapie. Physiotherapeut Daniel Volchek MSPhT und sein Team entwickeln in enger Abstimmung und Zusammenarbeit mit Dr. Andreas Kröner einen individuellen Therapieplan für unsere Patienten, um sowohl bei konservativen als auch bei operativen Eingriffen den Heilungsprozess bestmöglich zu unterstützen.

                        </div>
                        <div className={"service__link--container"}>
                            <Link className="service_link" to={"/physiotherapie"}>Mehr Information</Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <h2 className="service_subHead">Unsere Physiotherapeuten</h2>
                        </div>
                    </div>
                    <div className={"service__doctor--container"}>
                        {renderDoctors("Physiotherapie", props.doctors)}
                    </div>
                </div>


            </div>
        </div>
    );
}

export default Leistungen;