import React, {useLayoutEffect} from 'react'
import Main from './MainComponent'
import {useHistory} from 'react-router-dom'

const Root = () => {
    const history = useHistory();

    useLayoutEffect(() => {
        return history.listen((location) => {
            window.scrollTo(0,0);
        })
    }, [history]);

    return (
        <Main/>
    )
};

export default Root;