
import React from "react";

function HipSvg() {
    return (
        <svg viewBox="-7.074 -5.563 30 30" id="icon-huefte"><title>huefte</title>  <path display="none" d="M5.458,4.347c0,0,1.993,10.835-2.471,11.886c-2.27,0.534-3.362,0.813-2.872,2.203
	c0.401,1.135,4.946,0.813,5.943,0.734c2.538-0.2,3.677-5.167,8.346-7.011c2.538-1.002,1.348-4.821-0.334-5.676
	C11.809,5.445,11.546,1.766,11,0.874L5.458,4.347z"></path> <path display="none" d="M1.373,17.316c0,0-0.477-8.795-0.413-9.825c0.103-1.646,3.388-2.781,4.026,0
	c0,0,0.407,2.072-1.755,2.662c-0.454,0.124,0.413,7.178,0.413,7.178L1.373,17.316z"></path> <path display="none" d="M2.612,3.801V2.355c0,0,2.376-2.025,9.296-2.355l0.008,1.529
	C11.917,1.529,7.265,1.013,2.612,3.801"></path> <path display="none" d="M3.806,4.586c0,0-0.057,0.166,0.046,0.248c0.103,0.083,4.441,2.272,0.517,5.888
	c0,0-0.196,0.103,0.155,0.454c0.464,0.496,3.923,4.71,6.972,4.71c3.719,0,2.733-5.516,2.438-7.375
	C13.672,6.876,13.199,2.2,8.951,2.878C4.69,3.557,4.054,4.338,3.806,4.586"></path> <path className="color__icon" d="M3.907,20.154c0,0,0.104-5.081-1.239-6.197s1.136-2.752,1.962-1.446s1.15-0.62,1.162-0.826
	c0.026-0.445,0.363-1.894,2.137-1.063c1.555,0.729,0.223,2.045-0.131,2.22c-0.585,0.29-1.72,0.868-1.773,1.529
	c-0.078,0.956-0.052,5.788-0.052,5.788L3.907,20.154z"></path> <path className="color__icon" d="M9.175,12.841c0,0,0.084,3.257,2.737,3.492c1.162,0.103,2.065-0.388,3.15-1.938l-1.317-0.335
	c0,0-0.374,1.396-2.091,1.084c-1.162-0.211-1.136-1.885-1.136-1.885L9.175,12.841z"></path> <path className="color__icon" d="M13.85,11.323c-2.79-0.723-1.886-6.662-1.989-7.239c-0.104-0.577,0.604-3.429-3.512-3.968
	C4.398-0.4,3.34,2.131,3.34,2.131c-2.608,5.087,1.988,7.916,2.22,8.057c0,0,0.691-1.104,2.583-0.466
	c1.549,0.524,1.107,1.953,1.033,2.479c-0.026,0.181,5.888,1.549,5.888,1.549v-2.039L13.85,11.323z M7.084,1.588
	c-1.886,0.595-2.66,1.938-2.737,5.062c-0.011,0.432-0.063,0.33-0.064,0.428c0,0-1.628-5.464,3.083-5.721
	C7.365,1.356,7.522,1.459,7.084,1.588z"></path> </svg>
    );
}

export default HipSvg;