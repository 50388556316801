import React from "react";
import {FaPhone} from "react-icons/fa";
import {IoIosMail} from "react-icons/io";

function Info(props) {
    const baseUrl = "/images/";
    const doctor = props.location.state.doctor;

    const cv = doctor.cv.map(row => {
        return (
            <>
                <span className="cv__year">{row.year}</span>{row.text}<br/>
            </>
        )
    });

    const bulletPoints = doctor.fields.map(fieldName => {
        if (fieldName === "Orthopädie" || fieldName === "Physiotherapie") {
            return (
                <>

                </>)
        } else {
            return (
                <div key={fieldName}>
                    {fieldName}
                </div>
            );
        }
    });

    return (
        <div>
            <div className="banner__container">
                <div className="banner__text--container">
                    <span className="banner__info--text">{doctor.name}</span>
                </div>
                <div className={"banner__parallax service__parallax"}>
                </div>
            </div>

            <div className="container">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h2 className="info__title">{doctor.title}</h2>
                            <h1 className={"info__name"}>{doctor.name}</h1>
                            <p>
                                {doctor.short}
                            </p>
                            <p>
                                <FaPhone className="info__icon"/>
                                {doctor.phone}
                            </p>
                            <p>
                                <IoIosMail className="info__icon"/>
                                {doctor.mail}
                            </p>
                        </div>
                        <div className="col-lg-6">
                            <img className="info_image" src={baseUrl + doctor.image} alt={doctor.name}/>
                        </div>
                    </div>


                    {bulletPoints.length > 1 ?
                        <div className="row">
                            <div className="col info__bullet ">
                                <h2>Schwerpunkte</h2>
                                <p>{bulletPoints}</p>
                            </div>
                        </div>
                        : <></>}

                    <div className="row mb-4">
                        <div className="col">
                            {cv.length > 0 ? <><h2 className="mb-3">Lebenslauf</h2><p>{cv}</p></> : <></>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Info;