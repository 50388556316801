import React from "react";
import {Card, CardBody, CardImg, CardText, CardTitle} from "reactstrap";
import {Link} from "react-router-dom";

function RenderDoctors(props) {
    const field = props.data.field;
    const row =
        <div key={field.name}>
            <h1 className={"team__header"}>{field.category}</h1>
            <div className="row">
                {getDoctorForField(field, props)}
            </div>
        </div>

    return row;
}

function getDoctorForField(activeField, props) {
    const doctors = props.data.data.doctors;
    const baseUrl = "/images/";

    return doctors.map(doctor => {
        if (doctor.fields.includes(activeField.name)) {
            return (
                <div className="col-md-4 col-sm-6" key={doctor.id}>
                    <Card>
                        <CardImg className="cardImage" src={baseUrl + doctor.image} alt={doctor.name}/>
                        <CardBody>
                            <div className={"card__body--inner"}>
                                <CardTitle>{doctor.title} <br/><span className={"team__card--name"}>{doctor.name}</span></CardTitle>
                                <CardText>{doctor.short}</CardText>
                            </div>
                            {doctor.fields.includes("Backoffice") ? <div></div> : <Link to={{
                                pathname: "/info",
                                state: {
                                    doctor: doctor
                                }
                            }}>
                                <div className={"team__card--link"}>Mehr Information</div>
                            </Link>}
                        </CardBody>
                    </Card>
                </div>
            )
        } else {
            return (<div key={doctor.id}></div>)
        }
    })
}


function DoctorColumns(props) {
    return (
        <div>
            <div className="container-wrapper">
                <div className="container">
                    <main className="main" role="main">
                        <div className="container-fluid">

                            <RenderDoctors data={props} field={props.field}/>

                        </div>
                    </main>
                </div>
            </div>
        </div>
    );
}

export default DoctorColumns;