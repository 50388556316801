import React, {useLayoutEffect, useState} from "react";

const NavBarComponent = (props) => {
    const [clickedField, setClickedField] = useState(props.active ? props.active : props.fields[0].name);

    let list = props.fields.map(field => {
        return (
            <li key={field.name}
                className={"orthopaedie__navigation--item" + (clickedField === field.name ? " active" : "")}
                onClick={() => {
                    props.onclick(field);
                    setClickedField(field.name)
                }}>
                {field.name}
            </li>
        );
    });

    return (
        <nav role="navigation" className="orthopaedie__navigation--navbar">
            <ul className="orthopaedie__navigation--list">
                {list}
            </ul>
        </nav>
    );
};

// needs array
const ListComponent = (props) => {
    const [clickedField, setClickedField] = useState(props.fields[0].name);

    useLayoutEffect(() => {
            window.scrollTo(0,0);
    }, [clickedField]);

    const listItems = props.fields.map(subField => {
        return (
            <li key={subField.name}
                className={clickedField === subField.name ? "orthopaedie__list--item active" : "orthopaedie__list--item"}
                onClick={() => {
                    props.onclick(subField);
                    setClickedField(subField.name)
                }}>
                {subField.name}
            </li>
        )
    });

    return (

        <div className="col-lg-3 orthopaedie__list--responsive">
            <ul className="orthopaedie__list">
                {listItems}
            </ul>
        </div>

    )
};

//needs one field
const DescriptionComponent = (props) => {
    const k1 = {
        description: <div key={"k1"} className="">
            <h1>Vorderes Kreuzband</h1>
            <p>Die Kreuzbänder bilden die zentralen Stabilisierungspfeiler des Kniegelenkes. Ihre wesentliche Aufgabe
                ist
                die Sicherung des Kniegelenkes gegenüber Abbrems- und Beschleunigungsaktionen sowie Drehbewegungen.
                Verletzungen der Kreuzbänder betreffen zu über 90% das vordere Kreuzband (VKB). Die für den
                Kreuzbandriss
                prädisponierten Sportarten sind Fußball und Skifahren. Dabei kommt es meist zum sogenannten
                Flexions-Valgus-Außenrotations-Stress im Kniegelenk (nach Außendrehen des Kniegelenks bei gleichzeitiger
                X-Beinstellung), welcher zur Verletzung des Bandes führt. Gerade beim Skifahren kommt es sehr häufig zur
                sogenannten Unhappy-Triad-Verletzung des Kniegelenks. Dabei werden das vordere Kreuzband, der
                Innenmeniskus
                und das Innenband gleichzeitig verletzt.</p>
            <h3>
                <svg className="icon icon--wheel" aria-labelledby="title"><title></title>
                    {/*<use xlink:rel="noopener noreferrer" href="#icon-wheel"></use>*/}
                </svg>
                THERAPIE
            </h3>
            <p>Bei der Therapie einer vorderen Kreuzbandverletzung ist es meiner Meinung nach sehr wichtig, auf den
                individuellen Anspruch eines Patienten einzugehen. </p>
            <h4>Konservative Therapie</h4>
            <p>Handelt es sich um eine isolierte Ruptur des vorderen Kreuzbandes bei Patienten ohne sportlichen Anspruch
                (Stop and Go) und ohne subjektives Instabilitätsgefühl, kann ohne Weiteres auch ein konservativer
                Therapieversuch mit Muskelaufbauübungen begonnen werden. </p>
            <h4>Operative Therapie</h4>
            <p>Bestehen allerdings ein hoher sportlicher Anspruch und ein subjektives Instabilitätsgefühl, ist die
                Rekonstruktion des vorderen Kreuzbandes anzustreben. </p>
            <p>OP-Techniken: in den letzten Jahren hat sich international die anatomische Rekonstruktion des vorderen
                Kreuzbandes durchgesetzt. Diese Technik wird von uns schon seit 2007 routinemäßig durchgeführt, wobei
                wir
                auch hier je nach Anatomie und Sportart des Patienten die operative Methode anpassen: </p>
            <ul>
                <li>anatomische Doppelbündeltechnik mit der Semitendinosussehne</li>
                <li>anatomische Single-Bundle-Technik mit der Semitendinosussehne</li>
                <li>Rekonstruktion des VKB mit der Patellasehne</li>
                <li>Rekonstruktion des VKB mit der Quadricepssehne</li>
            </ul>
            <p>Spezielle Untersuchungen sind nach einer fehlgeschlagenen Kreuzbandplastik erforderlich. Somit werden vor
                einer Kreuzbandrevision immer eine MRT- und eine CT-Untersuchung (zur Beurteilung der Lage und der Größe
                der
                Bohrkanäle) durchgeführt. </p>
            <h4>
                <svg className="icon icon--arrow" aria-labelledby="title"><title></title>
                    {/*<use xlink:rel="noopener noreferrer" href="#icon-arrow"></use>*/}
                </svg>
                Nachbehandlung
            </h4>
            <p>Die Nachbehandlung nach einer VKB-Plastik ist von der OP-Technik und der eventuellen Mitbehandlung von
                Meniskus und Knorpel abhängig. Handelt es sich um eine isolierte VKB-Plastik, ist je nach
                Schwellungszustand
                nach 2 Wochen die volle Belastung des Kniegelenks möglich.</p>
            <h1>Hinteres Kreuzband</h1>
            <p>Hinter dem vorderen Kreuzband erscheint das hintere Kreuzband. Es kreuzt das vordere Kreuzband bei
                gebeugtem
                Knie in einem Winkel von ca. 90°. Es ist kräftiger und stellt insgesamt die kräftigste Bandstruktur des
                Kniegelenks dar. Die Ruptur des hinteren Kreuzbandes ist eine der schwersten Knieverletzungen.
                Ursächlich
                sind oftmals Unfallmechanismen mit hoher Gewalteinwirkung (Auto- und Motorradunfälle,
                Kniegelenksverrenkungen). Dies erklärt, dass bis zu 70% der Fälle mit Verletzungen der inneren oder
                äußeren
                stabilisierenden Strukturen kombiniert sind. </p>
            <h3>
                <svg className="icon icon--wheel" aria-labelledby="title"><title></title>
                    {/*// /!*<use xlink:rel="noopener noreferrer" href="#icon-wheel"></use>*!/*/}
                </svg>
                THERAPIE
            </h3>
            <h4>Operative Therapie</h4>
            <p>Wir haben uns auf die arthroskopische Rekonstruktion des hinteren Kreuzbandes spezialisiert. Wir
                verwenden
                dafür die Semitendinosussehne und die Gracilissehne, die über einen kleinen, 2–3 cm langen Schnitt an
                der
                Innenseite gewonnen werden. Die Rekonstruktion des hinteren Kreuzbandes erfolgt rein arthroskopisch.
                Etwaige
                Begleitverletzungen (Seitenbänder, Kapsel) werden in im Rahmen der Kreuzbandrekonstruktion
                mitversorgt.</p>
            <h4>
                <svg className="icon icon--arrow" aria-labelledby="title"><title></title>
                    {/*// /!*<use xlink:rel="noopener noreferrer" href="#icon-arrow"></use>*!/*/}
                </svg>
                Nachbehandlung
            </h4>
            <p>Die Nachbehandlung erfolgt im Vergleich zu der des vorderen Kreuzbandes deutlich verzögert. In den ersten
                6
                Wochen wird dem Patienten eine Spezialschiene (PTS-Schiene) angelegt. Die Mobilisierung erfolgt
                ausschließlich in Bauchlage unter physiotherapeutischer Betreuung, um eine hintere Schublade zu
                vermeiden
                (1. und 2. Woche 0–30°, 3 und 4. Woche 0–60°, 5 und 6. Woche 0–90°). Erst danach beginnt die aktive
                Phase
                der Rehabilitation.</p></div>, id: "k1"
    };
    const k2 = {
        description: <div key={"k2"}>
            <h1>Meniskus­verletzungen (Meniskus­läsionen)</h1>
            <p>Die Menisken (innerer = medialer und äußerer = lateraler Meniskus) sind zwei kleine halbmondförmige
                Knorpelscheiben inmitten des Gelenksspalts und bestehen hauptsächlich aus Kollagenfaser (etwa 90%),
                welche
                die Zugkräfte im Kniegelenk aufnehmen. Die Menisken verteilen und übertragen die Last, stabilisieren das
                Gelenk und wirken als „Stoßdämpfer“ für den Gelenkknorpel zwischen Ober- und Unterschenkel.<br/>
                Bei jungen Patienten führt meistens ein „Knieverdrehtrauma“ beim Sport zu einer Meniskusverletzung. Ab
                den 40.Lebensjahr sind es jedoch häufiger degenerative Meniskusschäden durch Überlastung oder vorzeitige
                Alterungsprozesse des Meniskusgewebes, die zu Meniskusrissen führen. Typisch für eine Meniskusverletzung
                sind Schmerzen im Bereich des Gelenksspaltes bei Streckung oder Beugung im Knie sowie
                Bewegungseinschränkungen.</p>
            <h3>
                <svg className="icon icon--wheel" aria-labelledby="title"><title></title>
                    {/*// /!*<use xlink:rel="noopener noreferrer" href="#icon-wheel"></use>*!/*/}
                </svg>
                THERAPIE
            </h3>
            <h4>Konservative Therapie</h4>
            <p>Bei Grad-I- und Grad-II-Veränderungen des Meniskus (nur in der MRT beurteilbar) ist keine Operation
                notwendig
                und ein Muskelaufbautraining Mittel der Wahl.</p>
            <h4>Operative Therapie</h4>
            <h5>Meniskusnaht</h5>
            <p>Bei Meniskusrissen (Grad III–IV) in der durchbluteten Zone (meistens traumatische Meniskusrisse) ist
                primär
                eine Naht bzw. eine Meniskusrekonstruktion anzustreben, um die Funktion des Meniskus zu erhalten. </p>
            <h5>Meniskusteilresektion</h5>
            <p>Nur wenn der Meniskus in der nicht durchbluteten Zone (meist degenerative Risse) gerissen ist, ist die
                Meniskusteilresektion das Mittel der Wahl. Hier werden nur die instabilen, störenden Meniskusteile
                entfernt;
                das stabile Meniskusgewebe wird belassen. Müssen jedoch mehr als zwei Drittel des Meniskus entfernt
                werden,
                ist ein Meniskusersatz zu diskutieren. <br/>
                Die Folge einer Meniskusresektion (geringer auch bei einer Teilresektion) ist jedoch eine Druckerhöhung
                im Gelenk um das 2,5- bis 6-fache. Die vermehrte Belastung des Knorpels kann längerfristig zu einer
                Abnützung und somit zur Arthrose mit Schmerzen im Gelenk führen, sodass häufig ein Gelenksersatz
                notwendig wird.</p>
            <h5>Meniskusersatz</h5>
            <p>Wenn ein großer Teil des Meniskus entfernt werden muss, gibt es seit einigen Jahren die Möglichkeit,
                diesen
                mittels eines Kollagenmeniskus (CMI-Meniskus) zu ersetzen. Die Meniskuszellen können über den
                durchbluteten
                Meniskusrest in das Transplantat einwachsen und so einen neuen Meniskus bilden. So können Form und
                Funktion
                des Meniskus wiederhergestellt und die Folgen einer Meniskusresektion vermieden werden. Dieses
                innovative
                Verfahren wird in unserer Knieambulanz des Otto-Wagner-Spitals schon seit längerer Zeit angeboten und
                wir
                konnten damit sehr gute Behandlungserfolge erzielen. </p>
            <h5>Wann kann ein Meniskusersatz durchgeführt werden?</h5>
            <p>Bestehen nach einer Meniskusteilresektion aufgrund der Überlastung des Gelenkes anhaltende Schmerzen
                (Postmeniskektomiesyndrom) oder wird eine Resektion von mehr als zwei Dritteln des Meniskus notwendig,
                ist
                ein Meniskusersatz anzudenken. <br/>
                Voraussetzungen für einen Meniskusersatz sind:</p>
            <ul>
                <li>stabiles Kniegelenk (Seiten- und Kreuzbänder sollten intakt sein)</li>
                <li>normale Beinachse – falls eine deutliche Fehlstellung (O- oder X Bein) besteht, kann oder sollte
                    diese
                    ebenfalls korrigiert werden.
                </li>
                <li>guter Gelenksknorpel ohne Zeichen einer fortgeschrittenen Arthrose</li>
                <li>junge Patienten (unter 50. Lebensjahr) mit gutem Regenerationspotential</li>
            </ul>
        </div>, id: "k2"
    };
    const k3 = {
        description: <div key={"k3"}>
            <h1>Knie­scheiben­verrenkung (Patella­luxation)</h1>
            <p>Die Patellaluxation ist eine der häufigsten Sportverletzungen. Bei ihr springt die Kniescheibe (Patella)
                aus
                ihrer v-förmigen knöchernen Führungsrinne am Oberschenkelknochen zur Außenseite (Luxation). Man
                unterscheidet die akute von der chronisch rezidivierenden Patellaluxation, die meist konstitutionell
                bedingt
                (angeboren) ist. Häufig kommt es begleitend zu einer Verletzung von Bändern auf der Innenseite des
                Kniegelenks sowie von Knochen und Knorpel an Kniescheibe und Oberschenkelknochen. Der Patient leidet bei
                einer Patellaluxation meist an einem vorderen Knieschmerz.</p>
            <h3>
                <svg className="icon icon--wheel" aria-labelledby="title"><title></title>
                    {/*<use xlink:rel="noopener noreferrer" href="#icon-wheel"></use>*/}
                </svg>
                THERAPIE
            </h3>
            <h4>Konservative Therapie</h4>
            <p>Reposition – in jedem Fall muss, falls dies nicht von selbst geschehen ist, die Kniescheibe eingerenkt
                werden. Hierbei wird das Knie langsam und vorsichtig wieder gestreckt, wobei die Kniescheibe fest mit
                der
                Hand geführt wird, damit sie nicht unvermittelt überspringt. Wenn die Kniescheibe vorsichtig in die
                Ausgangsposition geführt wird, kann diese ohne Begleitverletzung der Gelenkflächen und Knorpelschaden
                wieder
                reponiert werden. Nach der Reposition erfolgt eine sofortige deutliche Schmerzlinderung.</p>
            <h4>Operative Therapie</h4>
            <p>Bei entsprechender anatomischer Konstitution (Patelladysplasie) kann bereits nach der ersten
                Kniescheibenverrenkung die Indikation für eine operative Therapie gegeben sein, da in der Mehrzahl der
                Fälle
                mit einem Rezidiv zu rechnen ist. Bei der sogenannten rezidivierenden Patellaluxation gibt es
                verschiedene
                operative Maßnahmen, die dazu führen, dass die Patella nicht mehr luxieren und dabei den Knorpel
                schädigen
                kann.</p>
            <p>Durch neue wissenschaftliche Arbeiten konnte die Wichtigkeit des inneren Bandes zwischen Oberschenkel und
                Kniescheibe (MPFL) hervorgehoben werden. Somit ist es von enormer Bedeutung, bei Abriss des Bandes
                dieses
                durch eine Sehne (Gracilissehne) zu ersetzen. Dieses arthroskopisch assistierte Verfahren zeigt sich
                den
                herkömmlichen Verfahren als minimal-invasiv und in den mittelfristigen Ergebnissen überlegen. Wir
                konnten
                schon zahlreiche Patienten erfolgreich mit dieser Technik versorgen.</p></div>, id: "k3"
    };
    const k4 = {
        description: <div key={"k4"}>
            <h1>Seitenband- und Gelenk­kapsel­verletzungen</h1>
            <h2>Mediales Seitenband</h2>
            <p>Das Innenband (Ligamentum collaterale tibiale bzw. mediale) ist ein dreieckiges, flaches Band,
                welches breitflächig vom Aufsatz des inneren Oberschenkelknorrens zur Innenseite des
                Schienbeines verläuft. Es ist in die äußere Schicht der Gelenkkapsel eingebaut und mit dem
                Innenmeniskus verwachsen. Die Hauptfunktion besteht in der Stabilisierung des Kniegelenkes an
                der Innenseite gegen eine Auswärtsbewegung (X-Stress, Valgusstress) des Unterschenkels und einen
                zu starken Dreheffekt des Schienbeines gegenüber dem Oberschenkel nach außen. Häufig sind starke
                Gewalteinwirkungen, die den Unterschenkel nach außen drücken, oder auch eine Auswärtsdrehung
                Ursache der Verletzung. Die Verletzung des medialen Seitenbandes kann isoliert oder häufig als
                Kombinationsverletzung mit dem vorderen Kreuzband auftreten. </p>
            <h3>
                <svg className="icon icon--wheel" aria-labelledby="title"><title></title>
                    {/*/!*<use xlink:rel="noopener noreferrer" href="#icon-wheel"></use>*!/*/}
                </svg>
                THERAPIE
            </h3>
            <h3>Konservative Therapie</h3>
            <p>Verletzungen des Innenseitenbandes können aufgrund der guten Spontanheilungstendenz meist
                konservativ durch Ruhigstellung und Teilentlastung behandelt werden. </p>
            <h4>Operative Therapie</h4>
            <p>Eine Ausnahme bildet die komplette Zerreißung des medialen Kapselbandkomplexes mit Beteiligung
                des hinteren Schrägbandes und der Gelenkkapsel (dorsomediale Kapsel). Hier empfiehlt sich ein
                operativer Eingriff zur Naht der zerrissenen Bandstrukturen in den ersten 10 Tagen nach der
                Verletzung.</p>
            <h2>Laterales Seitenband</h2>
            <p>Das Außenband (Ligamentum collaterale fibulare bzw. laterale) ist ein kräftiges Band, welches
                zylinderförmig vom Aufsatz des seitlichen Oberschenkelknorrens zum Wadenbeinkopf zieht.
                Verletzungen des Außenbandes sind eher selten und treten meist als Kombinationsverletzung mit
                der hinteren Kapsel und dem hinteren oder vorderen Kreuzband auf. </p>
            <h3>
                <svg className="icon icon--wheel" aria-labelledby="title"><title></title>
                    {/*/!*<use xlink:rel="noopener noreferrer" href="#icon-wheel"></use>*!/*/}
                </svg>
                THERAPIE
            </h3>
            <h4>Konservative Therapie</h4>
            <p>Teilrisse des LSB und der posterolateralen Ecke können nicht-operativ mittels Ruhigstellung und
                Teilentlastung behandelt werden. </p>
            <h4>Operative Therapie</h4>
            <p>Die Außenbandverletzungen heilen im Gegensatz zu Verletzungen des Innenbandes meist nicht
                spontan. Bei akuter Verletzung (meist durch hohe Gewalteinwirkung) sollte versucht werden, die
                Kniegelenksaußenseite primär zu rekonstruieren. Bei knöchernen Bandausrissen kann man über
                Fadenanker in den Knochen eine Wiederherstellung der Stabilität erreichen. Eine übersehene oder
                unzureichend behandelte Verletzung des äußeren/hinteren Kapsel-Band-Komplexes kann zu einer
                chronischen Instabilität mit konsekutiver Immobilität des Patienten und zunehmender Abnützung
                des Kniegelenkes führen. Bei chronischen Instabilitäten müssen das Seitenband und die hintere
                äußere Gelenkkapsel mit einer Sehne (Gracilis-/Semitendinosussehne) ersetzt werden. Dies kann
                und sollte in einer Sitzung mit dem vorderen oder hinteren Kreuzband erfolgen.</p></div>, id: "k4"
    };
    const k5 = {
        description: <div key={"k5"}>
            <h1>Knieprothese</h1>
            <p>Bei schweren degenerativen Erkrankungen (Kniegelenksarthrose) ist eine
                Kniegelenksprothese oft die einzige Option, um eine schmerzfreie Bewegungsfähigkeit
                und Kniegelenksstabilität wieder herzustellen. </p>
            <h3>
                <svg className="icon icon--wheel" aria-labelledby="title"><title></title>
                    {/*/!*<use xlink:rel="noopener noreferrer" href="#icon-wheel"></use>*!/*/}
                </svg>
                THERAPIE
            </h3>
            <p>Die heutigen modernen Implantate werden als Oberflächenprothesen bezeichnet, da nur
                die abgenützte Knorpeloberfläche ersetzt wird. Je nach den Schweregrad der Arthrose
                und den individuellen Bedürfnissen des Patienten hat der Arzt die Möglichkeit aus
                verschiedenen Prothesentypen auszuwählen. <br/>
                Oberstes Prinzip für mich ist es nur die verschlissenen Anteile zu ersetzten und
                die nicht veränderten Areale zu erhalten.</p>
            <h4>Operative Therapie</h4>
            <h5>Teilendoprothese- Halbschlitten (unikondylärer Gelenksersatz)</h5>
            <p>Dieses Prothesenmodell setzt voraus, dass nur ein Kompartment von der Arthrose
                betroffen ist (medial – innen oder außen). Nachdem sich die Patienten nach einem
                Teilersatz deutlich schneller erholen als mit einer Totalendoprothese und die
                Funktionalität des Kniegelenks besser ist, ist die Teilendoprothese als Therapie der
                Wahl zu betrachten. </p>
            <h5>Patellofemoraler Oberflächenersatz</h5>
            <p>Ist nur die Kniescheibe und das Gleitlager der Kniescheibe von der Arthrose betroffen
                ist es möglich isoliert nur diesen Gelenksabschnitt zu ersetzten.</p>
            <h5>Totalendoprothese – bikondylärer Gelenksersatz</h5>
            <p>Der doppelseitige Oberflächenersatz kommt zum Einsatz, wenn das gesamte Kniegelenk
                von der Arthrose betroffen ist. Aufgrund des ständigen Fortschritts der Technik ist
                die Kinematik der neuen Prothesen den physiologischen Bewegungsabläufen des
                Kniegelenkes mittlerweile so ähnlich, dass die meisten Patienten vergessen operiert
                worden zu sein und wieder ihre sportlichen Hobbies ausüben können (Tennis, Laufen,
                Radfahren,....).</p>
            <h5>Gekoppelte Prothesen</h5>
            <p>Bei deutlichem Knochenverlust mit Seitenbandinsuffizienzen oder massiven
                Achsenfehlstellungen ist es selten auch notwendig auf ein gekoppeltes
                Prothesensystem zurück zu greifen.</p></div>, id: "k5"
    };
    const k6 = {
        description: <div key={"k6"}>
            <h1>Umstellungsosteotomie (Achsenkorrektur)</h1>
            <p>Eine stärkere Abweichung der Kniegelenkachse kann angeboren oder erworben sein. Dadurch kommt es zu einer
                einseitigen Belastung des inneren (O-Bein) oder äußeren (X-Bein) Gelenkabschnittes und dies führt zu
                entsprechender Abnützung, Meniskusverschleiß und Knorpelschaden. <br/>
                Die Fehlstellung kann angeboren oder erworbenen sein. Bei starken (&gt;10°) angeborenen Fehlstellungen
                ist es besonders wichtig rechtzeitig diese zu korrigieren, um der einseitigen Belastung und Arthrose
                vorzubeugen. <br/>
                Ein erworbenes X- oder O-Bein entsteht am häufigsten nach Knorpelschäden und Meniskusrissen. Wir führen
                bei erworbenen Fehlstellungen eine Achsenkorrektur durch, wenn die Patienten unter 65 Jahre alt sind und
                noch einen hohen sportlichen Anspruch haben.</p>
            <h3>
                <svg className="icon icon--wheel" aria-labelledby="title"><title></title>
                    {/*/!*<use xlink:rel="noopener noreferrer" href="#icon-wheel"></use>*!/*/}
                </svg>
                Therapie
            </h3>
            <h4>Operative Therapie</h4>
            <p>Ziel einer Umstellungsoperation die Entlastung der geschädigten Knorpelanteile durch Veränderung der
                Beinachse. Die Korrekturosteotomie erfolgt minimalinvasiv.<br/>
                In der Regel erfolgt die Knie Umstellungsosteotomie bei O-Beinen im Bereich des Unterschenkelkopfes.
                Nach exakter Planung erfolgt die aufklappende Tibiakopfosteotomie (open wedge) durch Aufspreizen des
                Unterschenkelkopfes auf der Innenseite. Der durchtrennte Knochen wird anschließend durch Platten,
                Schrauben oder Klammern stabilisiert. Der auf diese Weise künstlich erzeugte Spalt wird innerhalb
                relativ kurzer Zeit mit nachwachsendem Knochenmaterial aufgefüllt.<br/>
                Bei einer X-Beinfehlstellung erfolgt die Korrektur meistens am Oberschenkelknochen. Die beiden
                Knochenteile werden nach Entnahme des Knochenkeiles (closing wegde) durch eine Osteosynthese
                (Knochenmontage) mittels einer Platte mit winkelstabilen Schrauben wieder vereinigt. Diese
                winkelstabilen Platten haben eine enorme Stabilität und&nbsp;ermöglichen somit eine rasche Mobilisation
                und eine frühere Belastung des operierten Beines.</p></div>, id: "k6"
    };

    const f1 = {
        description: <div key={"f1"}>
            <h1>Hallux valgus (Frostballen)</h1>
            <p>Beim Hallux valgus handelt es sich um eine Schiefstellung der Großzehe im <a
                rel="noopener noreferrer" href="https://de.wikipedia.org/wiki/Zehengrundgelenk"
                target="_blank">Grundgelenk</a> nach außen hin.
                Die <a rel="noopener noreferrer" href="https://de.wikipedia.org/wiki/Sehne_%28Anatomie%29"
                       target="_blank">Sehnen</a> zu den
                Zehen verlaufen nicht mehr zentral über das <a rel="noopener noreferrer"
                                                               href="https://de.wikipedia.org/wiki/Gelenk"
                                                               target="_blank">Gelenk</a>, sondern weiter außen und
                ziehen die Zehen in eine schiefe Position. Somit tritt der Großzehenballen am Fußinnenrand oft
                deutlich hervor und es bilden sich häufig schmerzhafte <a
                    rel="noopener noreferrer" href="https://de.wikipedia.org/wiki/Entz%C3%BCndung"
                    target="_blank">Entzündungen</a>.<br/>
                Daneben kommt es durch Druck der Großzehe auf die kleinen Zehen oft zu <a
                    rel="noopener noreferrer" href="https://de.wikipedia.org/wiki/Hammerzehe"
                    target="_blank">Hammer-</a> und <a
                    rel="noopener noreferrer" href="https://de.wikipedia.org/wiki/Krallenzehe"
                    target="_blank">Krallenzehen</a>-Fehlstellungen
                der benachbarten Zehen.</p>
            <h3>
                <svg className="icon icon--wheel" aria-labelledby="title"><title></title>
                    {/*<use xlink:rel="noopener noreferrer" href="#icon-wheel"></use>*/}
                </svg>
                THERAPIE
            </h3>
            <h4>Konservative Therapie</h4>
            <p>Die einzig sinnvolle konservative Therapie ist die Vorbeugung! Dazu gehören richtiges Schuhwerk,
                Fußgymnastik und Barfußgehen. </p>
            <h4>Operative Therapie</h4>
            <p>Es gibt bei Hallux-valgus-Fehlstellung mehrere Möglichkeiten der operativen Therapie, die sich nach
                dem Schweregrad der Fehlstellung richten. Wir haben uns bei fortgeschrittenen Fehlstellungen auf die
                Scarf-Osteotomie (z-förmige Korrekturosteotomie des 1. Strahls der Mittelfußknochen) spezialisiert
                und erzielen mit dieser international anerkannten Methode ausgezeichnete Erfolge.</p>
            <h4>
                <svg className="icon icon--arrow" aria-labelledby="title"><title></title>
                    {/*// <use xlink:rel="noopener noreferrer" href="#icon-arrow"></use>*/}
                </svg>
                Nachbehandlung
            </h4>
            <p>Tragen eines speziellen Schuhes (Hallux-Schuh), mit dem allerdings volle Belastung erlaubt ist.
                Während dieser Zeit ist Fußgymnastik ein wichtiger Bestandteil der Heilgymnastik, um den Verlauf der
                Heilungsphase zu verkürzen.</p></div>, id: "f1"
    };
    const f2 = {
        description: <div key={"f2"}>
            <h1>Hallux rigidus – Arthrose des Groß­zehen­grund­gelenkes</h1>
            <p>Unter dem Krankheitsbild des Hallux rigidus wird die schmerzhafte Einsteifung des Großzehengrundgelenkes
                verstanden. Das Manifestationsalter liegt meist zwischen dem 30. und 50. Lebensjahr; Frauen sind
                häufiger
                betroffen als Männer. Als ätiologische Ursachen werden insbesondere Traumen, Probleme mit unpassendem
                Schuhwerk, eine hereditäre Häufung sowie bestehende Fußdeformitäten wie Plattfußfehlstellungen bzw. ein
                Metatarsus primus elevatus und eine Überlänge des Metatarsale 1 beschrieben.</p>
            <h3>
                <svg className="icon icon--wheel" aria-labelledby="title"><title></title>
                    {/*// <use xlink:rel="noopener noreferrer" href="#icon-wheel"></use>*/}
                </svg>
                THERAPIE
            </h3>
            <h4>Konservative Therapie</h4>
            <p>Spezielle Einlagen, Physiotherapie und Injektionen.</p>
            <h4>Operative Therapie</h4>
            <p>Im mäßig fortgeschrittenen Stadium hilft eine kleine Operation, bei der die Knochensporne an der oberen
                Seite
                des Gelenks entfernt werden. Dadurch kann die Beweglichkeit wiederhergestellt werden; eine
                Schmerzreduktion
                ist die Folge. Bei ausgedehnter Arthrose des Gelenks mit minimaler Restbeweglichkeit der Großzehe ist
                meist
                eine Versteifung (Arthrodese) das Mittel der Wahl. Die Arthrodese führt zu einer Beseitigung des durch
                die
                Arthrose hervorgerufenen Schmerzes. Sie verspricht bei Arthrosen des Großzehengelenks äußerst gute
                Ergebnisse und wurde mitunter auch bei Spitzensportlern erfolgreich eingesetzt.</p></div>, id: "f2"
    };
    const f3 = {
        description: <div key={"f3"}>
            <h1>Hammerzehen, Krallenzehen</h1>
            <p>Die häufigste Ursache für die Bildung von Hammerzehen ist der Spreizfuß mit Hallux-valgus-Deformität und
                „Schuh-Konflikt“. Hammerzehen können aber auch im Rahmen von neuromuskulären Erkrankungen, Hohlfuß,
                Kompartmentsyndrom, rheumatischen Erkrankungen oder Poliomyelitis entstehen.</p>
            <h3>
                <svg className="icon icon--wheel" aria-labelledby="title"><title></title>
                    {/*<use xlink:rel="noopener noreferrer" href="#icon-wheel"></use>*/}
                </svg>
                THERAPIE
            </h3>
            <p>Bei der Therapie der Hammerzehen ist es wichtig zu unterscheiden, ob es sich um eine korrigierbare, nicht
                fixierte oder um eine nicht korrigierbare und somit fixierte Hammerzehe handelt. </p>
            <h4>Operative Therapie</h4>
            <ul>
                <li>Bei flexibler, korrigierbarer Fehlstellung: Veränderung des Sehnenverlaufs oder Operation nach
                    Weil
                </li>
                <li>Bei Kontraktur: Operation nach Hohmann oder Resektionsarthroplastik, eventuell mit
                    Bohrdrahtfixierung
                    für 3 Wochen
                </li>
            </ul>
            <h4>
                <svg className="icon icon--arrow" aria-labelledby="title"><title></title>
                    {/*<use xlink:rel="noopener noreferrer" href="#icon-arrow"></use>*/}
                </svg>
                Nachbehandlung
            </h4>
            <p>Bequemes Schuhwerk für 6 Wochen. Volle Belastung erlaubt. Kein Laufsport für 8 Wochen.</p></div>,
        id: "f3"
    };
    const f4 = {
        description: <div key={"f4"}>
            <h1>Metatarsalgie, Morton-Neurom, Stress­frakturen</h1>
            <p>Schmerzhafte Erkrankungen im Bereich der Köpfchen der Mittelfußknochen.</p>
            <h2>Metatarsalgie</h2>
            <h3>
                <svg className="icon icon--wheel" aria-labelledby="title"><title></title>
                    {/*<use xlink:rel="noopener noreferrer" href="#icon-wheel"></use>*/}
                </svg>
                THERAPIE
            </h3>
            <h4>Konservative Therapie</h4>
            <p>Einlagen, Physiotherapie. </p>
            <h4>Operative Therapie</h4>
            <p>Chirurgische Korrektur der Fehlstellung bzw. der Überlänge (Weil-Osteotomie).</p>
            <h2>Morton-Neurom</h2>
            <h3>
                <svg className="icon icon--wheel" aria-labelledby="title"><title></title>
                    {/*<use xlink:rel="noopener noreferrer" href="#icon-wheel"></use>*/}
                </svg>
                THERAPIE
            </h3>
            <h4>Konservative Therapie</h4>
            <p>Einlagen, Infiltrationen, Physiotherapie.</p>
            <h4>Operative Therapie</h4>
            <p>Entfernung der verdickten Nerven.</p>
            <h2>Stressfraktur</h2>
            <h4>Konservative Therapie</h4>
            <p>Reduktion der Belastung, Einlagen, Walker, eventuell Gipsruhigstellung.</p></div>, id: "f4"
    };
    const f5 = {
        description: <div key={"f5"}>
            <h1>Achillessehne</h1>
            <h2>Riss/Teileinriss</h2>
            <p>Die Achillessehne kann komplett oder teilweise reißen. </p>
            <h3>
                <svg className="icon icon--wheel" aria-labelledby="title"><title></title>
                    {/*<use xlink:rel="noopener noreferrer" href="#icon-wheel"></use>*/}
                </svg>
                THERAPIE
            </h3>
            <h4>Konservative Therapie</h4>
            <p>Primär konservative Therapie mit Fersenerhöhung, Modelleinlagen zur Korrektur einer etwaigen
                Fehlstellung,
                Physiotherapie, Massagen.</p>
            <h4>Operative Therapie</h4>
            <p>Komplettrisse: Chirurgische Rekonstruktion der Sehne.<br/>
                Chronische Risse: Rekonstruktion mit Sehnenaugmentation.</p>
            <h2>Achilles­sehnen­entzündungen – Paratendinose, Schleim­beutel­entzündung</h2>
            <p>Ähnlich einer Sehnenscheidenentzündung an anderen Sehnen kommt es bei der Achillessehne zu einer
                Entzündung
                des umgebenden Gewebes mit häufiger Beteiligung des Schleimbeutels, einer Bursitis subachillea.</p>
            <h3>
                <svg className="icon icon--wheel" aria-labelledby="title"><title></title>
                    {/*<use xlink:rel="noopener noreferrer" href="#icon-wheel"></use>*/}
                </svg>
                THERAPIE
            </h3>
            <h4>Konservative Therapie</h4>
            <p>Modelleinlagen speziell für den Sport. Physiotherapie, exzentrische Übungen, Massagen, US,
                Stoßwellentherapie.</p>
            <h4>Operative Therapie</h4>
            <p>Abmeißelung des Überbeines, Entfernung des entzündeten Schleimbeutels, Rekonstruktion bzw. Anfrischen der
                Sehne.</p></div>, id: "f5"
    };

    const s1 = {
        description: <div key={"s1"}>
            <h1>Subacromial­syndrom​/​Impingement</h1>
            <p>Ein sogenanntes Engpassyndrom oder Impingement zwischen Oberarmkopf und Schulterdach ist der häufigste
                Grund
                für Schulterschmerzen ohne Trauma bei Überkopfarbeiten oder Überkopfsportlern. Häufig sind muskuläre
                Dysbalancen mit unzureichender Zentrierung des Oberarmkopfes im Gelenk Ursache des
                Subacromialsyndroms. </p>
            <h3>
                <svg className="icon icon--wheel" aria-labelledby="title"><title></title>
                    {/*<use xlink:rel="noopener noreferrer" href="#icon-wheel"></use>*/}
                </svg>
                THERAPIE
            </h3>
            <h4>Konservative Therapie</h4>
            <p>Der Großteil der Subacromialsyndrome ist mittels physikalischer Therapie und/oder Infiltrationen
                erfolgreich
                zu therapieren. Ziel der Krankengymnastik ist ein Ausgleich der auslösenden Dysbalancen mit Kräftigung
                der
                primären (Muskeln der Rotatorenmanschette) und sekundären Stabilisatoren (M. pectoralis major,
                latissimus
                dorsi und teres major).</p>
            <h4>Operative Therapie</h4>
            <p>Die Operation wird arthroskopisch durchgeführt und besteht darin, den zu engen subacromialen Raum zu
                vergrößern (Acromioplastik, subacromiale Dekompression). Mit demselben Eingriff wird auch der meist
                entzündete Schleimbeutel entfernt. Begleitpathologien (wie Kalkdepots, Risse im Bereich der
                Rotatorenmanschette oder Veränderungen der Bicepssehne) werden ebenfalls arthroskopisch
                mitbehandelt.</p>
            <h4>
                <svg className="icon icon--arrow" aria-labelledby="title"><title></title>
                    {/*<use xlink:rel="noopener noreferrer" href="#icon-arrow"></use>*/}
                </svg>
                Nachbehandlung
            </h4>
            <p>Die Beweglichkeit der Schulter nach einer arthroskopischen Dekompression ist ab dem ersten postoperativen
                Tag
                nicht limitiert und es kann sofort mit der Physiotherapie begonnen werden. Prinzipiell erfordert jeder
                Schultereingriff ein intensives physiotherapeutisches Nachbehandlungsprogramm. Die Dauer der
                Arbeitsunfähigkeit beträgt in Abhängigkeit von der beruflichen körperlichen Belastung zwischen 2 und 6
                Wochen. Wird in der gleichen Sitzung die Rotatorenmanschette genäht, muss der Patient für 6 Wochen einen
                speziellen Verband tragen. Die Schulter wird in dieser Zeit nur passiv vom Therapeuten durchbewegt. Erst
                danach beginnen die aktiv assistierten Übungen.</p></div>, id: "s1"
    };
    const s2 = {
        description: <div key={"s2"}>
            <h1>Rotatoren­manschetten­ruptur (RM)</h1>
            <p>Die vier Muskeln der Rotatorenmanschette (M. supraspinatus, infraspinatus, subscapularis und teres minor)
                ziehen durch den subacromialen Raum und setzen als einheitliche Sehnenmanschette an Knochenvorsprüngen
                des
                Oberarmkopfes an. Neben einer Kopfzentrierung ins Gelenk ermöglichen die Muskeln verschiedene Bewegungen
                (Heben, Innen- und Außendrehung) des Schultergelenkes. Risse in der Rotatorenmanschette betreffen meist
                die
                Supraspinatussehne und sind meist degenerativ, seltener traumatisch bedingt. </p>
            <h3>
                <svg className="icon icon--wheel" aria-labelledby="title"><title></title>
                    {/*<use xlink:rel="noopener noreferrer" href="#icon-wheel"></use>*/}
                </svg>
                THERAPIE
            </h3>
            <p>Die Therapie ist abhängig von der Defektgröße, dem Unfallmechanismus, dem Alter und der Aktivität des
                Patienten und dem Alter des Defektes (Retraktionsgrad der Sehne). </p>
            <h4>Operative Therapie</h4>
            <p>Die Operation erfolgt routinemäßig arthroskopisch. Ziele der Rekonstruktion sind die Wiederherstellung
                der
                Funktion (durch Naht der Sehne), die Zentrierung des Oberarmkopfes und der Erhalt des vollen
                Bewegungsumfanges des Gelenkes. </p>
            <h4>
                <svg className="icon icon--arrow" aria-labelledby="title"><title></title>
                    {/*<use xlink:rel="noopener noreferrer" href="#icon-arrow"></use>*/}
                </svg>
                Nachbehandlung
            </h4>
            <p>Prinzipiell erfordert jeder Schultereingriff ein intensives physiotherapeutisches
                Nachbehandlungsprogramm.
                Die Dauer der Arbeitsunfähigkeit beträgt in Abhängigkeit von der beruflichen körperlichen Belastung
                zwischen
                2 und 6 Wochen. Nach Naht der Rotatorenmanschette ist jedoch mit einer Nachbehandlungsdauer von bis zu 4
                Monaten zu rechnen, da der operierte Arm in den ersten 6 Wochen nur passiv durchbewegt werden kann. Erst
                dann kann mit einer aktiven Bewegungstherapie begonnen werden.</p></div>, id: "s2"
    };
    const s3 = {
        description: <div key={"s3"}>
            <h1>Acromio­clavicular­gelenk (AC)</h1>
            <h2>Verletzung des Acromio­clavicular­gelenkes (AC)</h2>
            <p>Das Acromioclaviculargelenk (AC = Schultereckgelenk) ist die Verbindung zwischen Schlüsselbein und
                Schulterdach.<br/>
                Verletzungen des AC-Gelenkes entstehen meist durch ein direktes Trauma der Schulter (Sturz auf die
                Schulter).</p>
            <h3>
                <svg className="icon icon--wheel" aria-labelledby="title"><title></title>
                    {/*<use xlink:rel="noopener noreferrer" href="#icon-wheel"></use>*/}
                </svg>
                THERAPIE
            </h3>
            <p>Die Therapie richtet sich nach dem Ausmaß der Verletzung und wird international in 6 Stadien nach
                Rockwood
                eingeteilt.</p>
            <h4>Konservative Therapie</h4>
            <p>Rockwood-Typ I-III.</p>
            <h4>Operative Therapie</h4>
            <p>Rockwood-Typ IV-VI.</p>
            <h2>Arthrose des Acromio­clavicular­gelenkes</h2>
            <p>Der Gelenksverschleiß im Acromioclaviculargelenk gehört zu den häufigsten Veränderungen des menschlichen
                Körpers und entsteht – wie alle Arthrosen – grundsätzlich posttraumatisch oder durch Überlastung. </p>
            <h3>
                <svg className="icon icon--wheel" aria-labelledby="title"><title></title>
                    {/*<use xlink:rel="noopener noreferrer" href="#icon-wheel"></use>*/}
                </svg>
                THERAPIE
            </h3>
            <h4>Konservative Therapie</h4>
            <p>Heilgymnastik, Infiltrationen und physikalische Therapie.</p>
            <h4>Operative Therapie</h4>
            <p>Offene oder arthroskopische Resektionsarthroplastik des AC-Gelenkes (Entfernung des äußeren Teiles des
                Schlüsselbeins)</p></div>, id: "s3"
    };
    const s4 = {
        description: <div key={"s4"}>
            <h1>SLAP-Läsion</h1>
            <h2>Veränderungen an der langen Bicepssehne (SLAP-Läsion)</h2>
            <p>Als „fünfte Sehne“ der Rotatorenmanschette kann die lange Bicepssehne angesehen werden. Verletzungen des
                Labrums (häufig bei Überkopfsportlern) werden als „SLAP-Läsion“ bezeichnet. Typisch für SLAP-Läsionen
                ist,
                dass sie eine unklare Symptomatik verursachen. Ein Knacken oder Schnappen in der Schulter kann ein
                Hinweis
                sein. Manche Patienten verspüren ein Instabilitätsgefühl, als springe die Schulter bei bestimmten
                Bewegungen
                hinein und heraus. Der Schmerz wird von dem Betroffenen tief in der Schulter empfunden. </p>
            <h3>
                <svg className="icon icon--wheel" aria-labelledby="title"><title></title>
                    {/*<use xlink:rel="noopener noreferrer" href="#icon-wheel"></use>*/}
                </svg>
                THERAPIE
            </h3>
            <p>Bei der <strong>operativen Therapie</strong>, die arthroskopisch erfolgt, wird entweder das Labrum
                refixiert
                oder die Bicepssehne durchtrennt und am Oberarmkopf neu befestigt (Bicepssehnentenodese). Bei
                degenerativen
                Veränderungen der Bicepssehne wird ebenfalls eine Tenodese oder Tenotomie derselben durchgeführt.</p>
            <p>Die <strong>Nachbehandlung</strong> richtet sich nach der Operation und reicht von der raschen
                Mobilisierung
                nach Tenodese bis zur 6-wöchigen Ruhigstellung nach Labrum-Refixation, begleitet von mehrwöchiger
                Physiotherapie.</p></div>, id: "s4"
    };
    const s5 = {
        description: <div key={"s5"}>
            <h1>Schultersteife (Frozen Shoulder)</h1>
            <p>Unter der Frozen Shoulder versteht man chronische, <a
                rel="noopener noreferrer" href="http://flexikon.doccheck.com/de/Entz%C3%BCndung"
                target="_blank">entzündliche</a> Veränderungen im
                Bereich der <a rel="noopener noreferrer" href="http://flexikon.doccheck.com/de/Gelenkkapsel"
                               target="_blank">Schultergelenkkapsel</a> (adhäsive Kapsulitis), die zu einer temporären
                Einsteifung des <a rel="noopener noreferrer" href="http://flexikon.doccheck.com/de/Schultergelenk"
                                   target="_blank">Schultergelenks</a> führen. </p>
            <h3>
                <svg className="icon icon--wheel" aria-labelledby="title"><title></title>
                    {/*<use xlink:rel="noopener noreferrer" href="#icon-wheel"></use>*/}
                </svg>
                THERAPIE
            </h3>
            <h4>Konservative Therapie</h4>
            <p>Intensive Heilgymnastik, entzündungshemmende Infiltrationen und/oder Medikamente.</p>
            <h4>Operative Therapie</h4>
            <p>Bei anhaltender Bewegungseinschränkung und Schmerzen nach 6 Monaten konservativer Therapie können die
                Arthroskopie der Schulter (operative Durchtrennung der entzündeten Kapsel) und die Mobilisation der
                Schulter
                in Narkose in Betracht gezogen werden.</p></div>, id: "s5"
    };
    const s6 = {
        description: <div key={"s6"}>
            <h1>Schulter­luxation</h1>
            <p>Unter einer Schulterluxation versteht man eine Ausrenkung des <a
                rel="noopener noreferrer" href="https://de.wikipedia.org/wiki/Schultergelenk"
                target="_blank">Schultergelenks</a>. Sie kann durch
                einen <a rel="noopener noreferrer" href="https://de.wikipedia.org/wiki/Trauma_%28Medizin%29"
                         target="_blank">Unfall</a> (traumatische
                Luxation) oder anlagebedingt (habituelle Luxation) verursacht sein.</p>
            <h3>
                <svg className="icon icon--wheel" aria-labelledby="title"><title></title>
                    {/*<use xlink:rel="noopener noreferrer" href="#icon-wheel"></use>*/}
                </svg>
                THERAPIE
            </h3>
            <p>Die Therapie der Erstluxation richtet sich nach dem Alter, dem Aktivitätsgrad und den Begleitverletzungen
                des
                Patienten. </p>
            <p>Wenn die Luxation in verschiedenen Ebenen (multidirektional) vom Patienten spontan provoziert werden
                kann,
                ist die <strong>konservative Therapie</strong> mit Aufbau und Koordination bestimmter Muskelgruppen
                indiziert.</p>
            <p>Handelt es sich um einen jungen, aktiven Patienten mit einer Bankart-Läsion, ist die neuerliche Luxation
                des
                Schultergelenkes sehr wahrscheinlich. In solchen Fällen stellen wir die Indikation zur
                stabilisierenden <strong>Operation</strong>. Die Operation erfolgt arthroskopisch mit dem Ziel, den
                knorpeligen Pfannenrand (Labrum) wieder zu rekonstruieren. </p>
            <h4>
                <svg className="icon icon--arrow" aria-labelledby="title"><title></title>
                    {/*<use xlink:rel="noopener noreferrer" href="#icon-arrow"></use>*/}
                </svg>
                Nachbehandlung
            </h4>
            <p>Im Anschluss an die Operation erfolgt eine 3-wöchige Ruhigstellung mit einem entsprechenden Verband.
                Bereits
                in einer frühen postoperativen Phase sind Pendelbewegungen möglich. Außenrotationsbewegungen sind in
                dieser
                Zeit jedoch unbedingt zu vermeiden. Anschließend erfolgt eine intensive Physiotherapie für mindestens
                weitere 4 Wochen, bis die volle Funktionalität der Schulter wiederhergestellt ist.</p></div>, id: "s6"
    };
    const s7 = {
        description: <div key={"s7"}>
            <h1>Kalk­schulter</h1>
            <p>Der kalzifizierenden <a rel="noopener noreferrer" href="https://de.wikipedia.org/wiki/Tendinitis"
                                       target="_blank">Tendinosis</a> (Kalkschulter) liegen kalkartige Ablagerungen in
                Sehnen und <a rel="noopener noreferrer" href="https://de.wikipedia.org/wiki/Sehne_%28Anatomie%29"
                              target="_blank">Sehnenansätzen</a> zugrunde. Sie kommen besonders an der <a
                    rel="noopener noreferrer" href="https://de.wikipedia.org/wiki/Rotatorenmanschette"
                    target="_blank">Rotatorenmanschette</a> des <a
                    rel="noopener noreferrer" href="https://de.wikipedia.org/wiki/Schulter"
                    target="_blank">Schultergelenkes</a> vor. Hier ist am
                häufigsten die <a rel="noopener noreferrer" href="https://de.wikipedia.org/wiki/Supraspinatus"
                                  target="_blank">Supraspinatussehne</a> betroffen. </p>
            <h3>
                <svg className="icon icon--wheel" aria-labelledby="title"><title></title>
                    {/*<use xlink:rel="noopener noreferrer" href="#icon-wheel"></use>*/}
                </svg>
                THERAPIE
            </h3>
            <h4>Konservative Therapie</h4>
            <p>Die Kalkdepots können mittels mehrerer ultraschall-gezielter Infiltrationen zur Auflösung gebracht
                werden.
                Als Alternative spielt die Stoßwellentherapie eine bedeutende Rolle.</p>
            <h4>Operative Therapie</h4>
            <p>Erst nach einer erfolglosen konservativen Therapie wird der Kalkherd arthroskopisch entfernt und der
                subacromiale Raum vergrößert.</p></div>, id: "s7"
    };

    const h1 = {
        description: <div key={"h1"}>
            <h1>Coxarthrose</h1>
            <p>Als <strong>Coxarthrose</strong> bezeichnet man die <a
                rel="noopener noreferrer" href="http://flexikon.doccheck.com/de/Arthrose"
                target="_blank">Arthrose</a> des Hüftgelenks, d.h.
                eine <a rel="noopener noreferrer" href="http://flexikon.doccheck.com/de/Degenerativ"
                        target="_blank">degenerative</a>, langsam
                fortschreitende, primär nicht-entzündliche Erkrankung. Eine Coxarthrose führt zu Schmerzen und
                Funktionseinschränkungen in der Hüfte. </p>
            <h3>
                <svg className="icon icon--wheel" aria-labelledby="title"><title></title>
                    {/*<use xlink:rel="noopener noreferrer" href="#icon-wheel"></use>*/}
                </svg>
                THERAPIE
            </h3>
            <h4>Konservative Therapie</h4>
            <ul>
                <li>Physiotherapie zum Erhalt der Gelenksbeweglichkeit</li>
                <li>Infiltrationen („Knorpelkuren“) zur Verhinderung der Progression der Gelenksabnützung</li>
                <li>Physikalische Therapie</li>
            </ul>
            <h4>Operative Therapie</h4>
            <p>Beim Ersatz des Hüftgelenkes (Hüft-Totalendoprothese) setzen wir auf Prothesenmodelle, die
                hervorragende Langzeitergebnisse (mehr als 20 Jahre) mit optimaler Stabilität und geringstem Abrieb
                der Gleitpaarungen aufweisen. Da die Genesungsdauer hauptsächlich von der Durchtrennung bzw.
                Schonung der Muskulatur abhängt, wurde in den letzten zehn Jahren eine minimal-invasive Technik
                entwickelt. Mit dieser Technik kann nicht nur der Hautschnitt von über 20 cm auf etwa 10 cm
                reduziert werden, sondern es gelingt auch, dabei die Muskulatur zu schonen. Somit können die
                Genesungsdauer und der postoperative Schmerz deutlich verringert werden.</p>
            <p>Vorteile der minimal-invasiven Hüftprothese:</p>
            <ul>
                <li>Keine Durchtrennung der Muskulatur</li>
                <li>Keine postoperative Muskelinsuffizienz</li>
                <li>Patienten sind rascher wieder mobil (nur 4 Wochen Krücken mit Belastung), kürzerer Aufenthalt im
                    Spital
                </li>
                <li>Stiegensteigen ist schon kurze Zeit nach der OP möglich</li>
                <li>Autofahren nach 4 Wochen</li>
            </ul>
        </div>, id: "h1"
    };
    const h2 = {
        description: <div key={"h2"}>
            <h1>Femoro-acetabuläres Impingement-Syndrom (FAI)</h1>
            <p>Bei dem Femoro-acetabulären Impingement-Syndrom handelt es sich um ein Engesyndrom zwischen
                Hüftkopf und -pfanne. Durch bestimmte Bewegungen (Drehung und Beugung des Beines) kommt es zu
                einem knöchernen Anstoßen zwischen dem Oberschenkel und der Hüftpfanne mit daraus resultierendem
                Knochenzuwachs und typischem Schmerzbild.</p>
            <h3>
                <svg className="icon icon--wheel" aria-labelledby="title"><title></title>
                    {/*<use xlink:rel="noopener noreferrer" href="#icon-wheel"></use>*/}
                </svg>
                THERAPIE
            </h3>
            <h4>Operative Therapie</h4>
            <p>Ziel der Therapie ist es, die Ursache für das Impingement (Knochenanlagerungen) zu beseitigen.
                Dies kann minimal-offenchirurgisch oder arthroskopisch erfolgen. Im Otto-Wagner-Spital wird
                diese endoskopische Technik in den letzten Jahren erfolgreich angewendet.</p></div>, id: "h2"
    };
    const h3 = {
        description: <div key={"h3"}>
            <h1>Knochen­mark­ödem­syndrom (KMÖS)</h1>
            <p>Unter einem KMÖS versteht man eine vermehrte Wasseransammlung im Knochen, die auf einer Ödem-
                oder Hämatombildung im Knochen beruht. <br/>
                Klinisch zeigen die Patienten akute, langsam zunehmende Belastungs- und Leistenschmerzen mit
                einem hinkenden Gangbild, selten Nacht- oder Ruheschmerzen.</p>
            <h3>
                <svg className="icon icon--wheel" aria-labelledby="title"><title></title>
                    {/*<use xlink:rel="noopener noreferrer" href="#icon-wheel"></use>*/}
                </svg>
                THERAPIE
            </h3>
            <h4>Konservative Therapie</h4>
            <p>Das KMÖS wird vorwiegend konservativ behandelt. Meist ist die Entlastung des Gelenkes mittels
                Unterarmstützkrücken unumgänglich. Unterstützend kann eine Infusionstherapie zur Verbesserung
                der Hüftkopfdurchblutung über 5 Tage durchgeführt werden. </p>
            <h4>Operative Therapie</h4>
            <p>Alternativ ist eine Entlastungsbohrung des Hüftkopfes („Core Decompression“) in Erwägung zu
                ziehen.</p></div>, id: "h3"
    };


    const arr = [k1, k2, k3, k4, k5, k6, f1, f2, f3, f4, f5, s1, s2, s3, s4, s5, s6, s7, h1, h2, h3];
    const sort = arr.map(element => {
        if (element.id === props.field.id) {
            return <div className="sort__container">{element.description}</div>;
        } else {
            return (<div key={element.id}></div>)
        }
    });

    return (
        <div className="col-lg-9 orthopaedie__description--container">
            {sort}
        </div>
    )
};

const ContentComponent = (props) => {
    return (
        <div className="orthopaedie__background">
            <div className="container-fluid">
                <div className="row">
                    <ListComponent fields={props.listFields} onclick={props.setActiveListField}/>
                    <DescriptionComponent field={props.activeListField}/>
                </div>
            </div>
        </div>
    )
};

function DiagnosticComponent(props) {
    const fields = props.fields.children;
    const location = props.location.location;

    const find = fields.filter(child => {
        if (location.state) {
            if (child.name === location.state.active) {
                return child;
            }
        }
        return undefined;
        //todo return, navbar
    });

    const [navbarField, setNavbarField] = useState(find[0] ? find[0] : fields[0]);
    const [activeListField, setActiveListField] = useState(navbarField.children[0]);


    return (
        <div>
            <NavBarComponent fields={fields} active={find[0] ? find[0].name : "knie"} onclick={(field) => {
                setNavbarField(field);
                setActiveListField(field.children[0]);
            }}/>
            <ContentComponent activeListField={activeListField} listFields={navbarField.children} setActiveListField={setActiveListField}/>
        </div>
    );
}

export default DiagnosticComponent;