import React, {Component} from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Root from './components/RootComponent';


class App extends Component {

    render() {
        return (
            <BrowserRouter>
                <div className="App" id="wrapper">
                    <Root/>
                </div>
            </BrowserRouter>
        );
    }
}


export default App;