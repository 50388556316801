import React from "react";
import {Link} from "react-router-dom";


function Footer(props) {
    let i = 0;
    const doctors = props.doctors.map(doctor => {
        const dynamicClassName = () => {

            if (i < 5) {
                return "footer__doctor--first"
            }else{
                if (i > 8) {
                    return "footer__doctor--third"
                }
            }
            return "footer__doctor--second"

        };
        i++;
        return (
            <div className={"footer__doctor " + dynamicClassName()} key={doctor.id}>
                {doctor.fields.includes("Backoffice") ? <div>{doctor.name}</div> : <Link to={{
                    pathname: "/info",
                    state: {
                        doctor: doctor
                    }
                }} className={"footer__doctor"}>
                    {doctor.name}
                </Link>}
            </div>
        );
    });

    // "footer__doctor-left"
    return (
        <footer className="footer_own" role="contentinfo">
            <div className="footer__container">
                <div className="footer__item left__foot">
                    <h3 className="footer_head left__head">Über uns</h3>
                    <ul className="footer_list">
                        <li><Link to={"/kontakt"}>Termin</Link></li>
                        <li><Link to={"/kontakt"}>Kontakt</Link></li>
                    </ul>
                </div>
                <div className="footer__item main__foot">
                    <h3 className="footer_head main__foot--head"><Link to={"/team"}>Team</Link></h3>
                    <div className="footer__doctor--list">
                        {doctors}
                    </div>
                </div>
                <div className="footer__item right__foot">
                    <h3 className="footer_head">Information</h3>
                    <ul className={"footer_list"}>
                        <li><Link to={"/datenschutz"}>Datenschutz</Link></li>
                        <li className="footer_color"><Link to={"/datenschutz"}>Impressum</Link></li>
                    </ul>
                </div>
            </div>
        </footer>
    );

}

export default Footer;
