import React from "react";
import DoctorColumns from "./DoctorColumnComponent";



function Stosswellentherapie(props) {
    return (
        <React.Fragment>
            <div className={"physio__background"}>

                <div className="banner__container">
                    <div className="banner__text--container">
                        <span className="banner__info--text long" style={{width:"400px"}}>Stosswellentherapie</span>
                    </div>
                    <div className={"service__parallax carousel__img--item stosswelle__parallax"}>
                    </div>
                </div>
            </div>
            <div className="container underline">
                <h2>STOSSWELLENTHERAPIE</h2>
                <p>Die extrakorporale Stoßwellentherapie (ESWT) ist eine moderne Behandlungsmethode,
                bei der Schallwellen mit hoher Energie in den Körper eingeleitet werden und dabei die Selbstheilung
                des Körpers aktiviert wird. Die Durchblutung und der Stoffwechsel der behandelten Region wird verstärkt
                und geschädigtes Gewebe kann so schnell regenerieren. In der Urologie wird die Stoßwellenbehandlung schon sehr
                lange zur Zertrümmerung von Nieren- und Harnleitersteinen eingesetzt. In den letzten Jahren hat sich gezeigt,
                dass hervorragende Behandlungserfolge auch im Bereich der Muskeln, Sehnen und Gelenke mittels Stoßwellentherapie
                erzielt werden. Es wir wird je nach Indikation eine radiale und/oder eine fokussierte Stoßwellentherapie empfohlen (s.u.).
                Erst durch die Kombination aus beiden Therapieformen lässt sich in der Regel ein optimaler Therapieerfolg  erzielen.</p>

                <div className="d-flex justify-content-center">
                    <h2 className="mt-4 mb-4 text-center">Welche Behandlungsoptionen gibt es?</h2>
                </div>
                <ul className="list-inline text-center" >
                    <li className="list-inline-item font-weight-400">Achillodynie</li>
                    <li className="list-inline-item font-weight-400">Faszientherapie</li>
                    <li className="list-inline-item font-weight-400">Tibiakantensyndrom</li>
                    <li className="list-inline-item font-weight-400">Patellar Tendonitis<br /></li>
                    <li className="list-inline-item font-weight-400">Fasciitis plantaris</li>
                    <li className="list-inline-item font-weight-400">Tendinosis Calcarea</li>
                    <li className="list-inline-item font-weight-400">Chronische Nacken-, Rücken- und Schulterbeschwerden</li>
                    <li className="list-inline-item font-weight-400">Trochanter-Tendinopathie</li>
                    <li className="list-inline-item font-weight-400">Triggerpunkt-Therapie</li>
                    <li className="list-inline-item font-weight-400">Adduktoren Oberschenkel</li>
                    <li className="list-inline-item font-weight-400">Wadenmuskelverkürzung</li>
                </ul>
                <div className="row projects mt-4">
                    <div className="col-sm-6 item">
                        <div className="row">
                            <div className="col-md-12 col-lg-5"><img className="img-fluid" src="images/Ellenbogen.png" alt="Ellbogen" /></div>
                            <div className="col">
                                <h3 className="name" ><strong>Ellbogen</strong><br /></h3>
                                <ul>
                                    <li>Unterarmmuskulatur<br /></li>
                                    <li>Epicondylitis (Tennis- oder<br /> Golferellenbogen<br /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 item">
                        <div className="row">
                            <div className="col-md-12 col-lg-5"><img className="img-fluid" src="images/Knie.png" alt="Knie" /></div>
                            <div className="col">
                                <h3 className="name" ><strong>Knie und Schienbein</strong><br /></h3>
                                <ul>
                                    <li>Patellar Tendonitis (Jumper&#39;s Knee)</li>
                                    <li>Tibiakanten-Syndrom (Schienbeinkantenschmerz)</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 item">
                        <div className="row">
                            <div className="col-md-12 col-lg-5"><img className="img-fluid" src="images/Ferse.png" alt="Ferse"/></div>
                            <div className="col">
                                <h3 className="name"><strong>Achillessehne und Ferse</strong><br /></h3>
                                <ul>
                                    <li>Achillodynie (Schmerzsyndrom der Achillessehne)</li>
                                    <li>Plantare Fussmuskulatur</li>
                                    <li>Fasciitis Plantaris (Fersensporn)</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 item">
                        <div className="row">
                            <div className="col-md-12 col-lg-5"><img className="img-fluid" src="images/Schulter.png" alt="Schulter"/></div>
                            <div className="col">
                                <h3 className="name"><strong>Schulter und Nacken</strong><br /></h3>
                                <ul>
                                    <li>Periartikuläre Schulterschmerzen</li>
                                    <li>Chronische Nacken- und Schulterbeschwerden</li>
                                    <li>Tendinosis Calcarea (Kalkschulter)</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 item">
                        <div className="row">
                            <div className="col-md-12 col-lg-5"><img className="img-fluid" src="images/Ruecken.png" alt="Rücken"/></div>
                            <div className="col">
                                <h3 className="name"><strong>Rücken</strong><br /></h3>
                                <ul>
                                    <li>Lumbalgie (Hexenschuss)<br /></li>
                                    <li>Dorsalgie (Chronische Rückenschmerzen)<br /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <h2 className="mt-4">Wie funktionieren Stosswellen?</h2>
                <p>Stoßwellen sind energiereiche, hörbare Schallwellen. 
                    In der Medizin werden Stoßwellen bereits seit 1980 u. a. zur Auflösung von Nierensteinen eingesetzt.</p>
                <p>Durch den Einsatz der Stoßwelle wird im behandelten Gewebe das Nervensystem stimuliert und zusätzlich Stickoxide und 
                    andere Botenstoffe freigesetzt. Zu den Folgewirkungen gehören die Steigerung des Stoffwechsels und Neoangionese sowie die 
                    Freisetzung vaskulärer endothelialer Wachstumsfaktoren, die letztendlich zu einer Reduktion von chronischen Schmerzen führen können. 
                    Stoßwellen können den Heilungsprozess im Körper beschleunigen, die Selbstheilungskräfte werden in Gang gesetzt. 
                    Der Stoffwechsel verbessert sich, die Durchblutung wird gesteigert, geschädigtes Gewebe kann sich regenerieren und ausheilen.</p>
                <p>In der modernen Schmerztherapie wird die Energie der Stoßwellen auf die Schmerzzonen im menschlichen Körper übertragen. 
                    Dort kann sie ihre heilende Wirkung entfalten. Heilungsprozesse im Körper können beschleunigt werden, 
                    der Stoffwechsel wird angeregt, die Durchblutung wird gesteigert, geschädigtes Gewebe kann sich regenerieren und ausheilen.</p>
                <p>Langjährige Erfahrungen zeigen, dass mit Hilfe dieses innovativen Heilverfahrens bestimmte krankhafte Veränderungen an 
                    Sehnen, Bändern, Kapseln, Muskeln und Knochen, die Ursachen Ihrer Schmerzen, gezielt beseitigt werden können.</p>
                <p>Die Extrakorporale Stoßwellentherapie ist – von qualifizierten Therapeuten durchgeführt – 
                    eine weitgehend risiko- und nebenwirkungsfreie Methode, um den Schmerz zu behandeln.</p>
                    <h2 className="mt-4">Wie läuft eine Stoßwellenbehandlung ab?</h2>
                <p>Der Arzt lokalisiert die Schmerzzone durch Tastbefund oder Stoßwellenortung und bespricht das Krankheitsbild mit Ihnen.</p>
                <p>Die Schmerzzone wird mit dem Therapiekopf umkreist, bei gleichzeitigem Einleiten der Stoßwellen. Es kann auf den zu behandelnden Bereich ein Hautgel aufgetragen werden, 
                    um die Stoßwellen ohne Energieverluste in den Körper einzuleiten.</p>
                <p>Die Behandlung dauert etwa 50 Minuten, je nach Krankheitsbild.
                    Durchschnittlich sind drei bis fünf Sitzungen im Wochenabstand erforderlich.</p>
                <p>Viele Patienten berichten bereits nach ein bis zwei Sitzungen, schmerzfrei zu sein oder deutlich verminderte Schmerzen zu verspüren. 
                    Wiedergewonnene Schmerz- oder Bewegungsfreiheit gibt ein Stück Lebensqualität zurück.</p>
            </div>

            <DoctorColumns data={props.data} field={props.data.fields[3]}/>
        </React.Fragment>
    );
}


export default Stosswellentherapie;