import React from "react";

function FootSvg() {
    return (
        <svg viewBox="-7.074 -3.561 30 30" id="icon-fuss"><title>fuss</title>  <path display="none" d="M7.335,21.93l-0.16-0.109c-1.37-0.938-6.435-8.225-6.987-11.814
        C-0.06,8.393,1.526,6.235,4.902,3.595c2.45-1.917,5.001-3.417,5.027-3.433L10.207,0l2.16,2.806L12.02,3.018
        C11.693,3.217,3.999,7.904,3,8.449C2.602,8.666,2.473,9.097,2.432,9.334c-0.089,0.513,0.038,1.085,0.31,1.391
        c0.256,0.288,0.716,0.625,1.169,0.625c0.36,0,0.689-0.219,0.979-0.65c1.278-1.906,7.838-6.329,8.117-6.517l0.301-0.202l2.058,3.032
        l-0.103,0.193c-0.037,0.069-0.941,1.707-4.706,2.554c-1.754,0.395-2.471,0.712-2.757,0.885c0.673,0.422,2.109,1.502,2.487,3.237
        c0.444,2.045,1.793,5.651,1.807,5.688l0.123,0.326l-2.511,1.076L9.537,20.72c-0.413-0.618-4.011-6.103-3.323-8.079
        c0.071-0.204,0.042-0.394-0.09-0.578c-0.229-0.322-0.672-0.509-0.925-0.457C5,11.646,4.934,11.732,4.824,11.877
        c-0.146,0.193-0.366,0.485-0.895,0.485c-0.072,0-0.152-0.005-0.241-0.018c-0.225-0.033-0.509,0.13-0.675,0.366
        c-0.079,0.111-0.196,0.338-0.104,0.597c0.198,0.55,3.583,4.968,5.668,7.623l0.301,0.383L7.335,21.93z"></path> <path display="none" d="M1.869,9.063c0,0-0.161,1.316,0.193,1.736c0.654,0.776,0.245,1.021-0.096,0.89
        c-0.405-0.157-1.069-1.038-0.907-1.848C1.22,9.03,1.934,8.544,1.869,9.063"></path> <path className="color__icon" d="M5.458,6.35c0,0,1.993,10.835-2.471,11.886c-2.27,0.534-3.362,0.813-2.872,2.203
        c0.401,1.135,4.946,0.813,5.943,0.734c2.538-0.2,3.677-5.167,8.346-7.011c2.538-1.002,1.348-4.821-0.334-5.676
        C11.809,7.448,11.546,3.769,11,2.877L5.458,6.35z"></path> <path display="none" d="M1.373,19.319c0,0-0.477-8.795-0.413-9.825c0.103-1.646,3.388-2.781,4.026,0
        c0,0,0.407,2.072-1.755,2.662c-0.454,0.124,0.413,7.178,0.413,7.178L1.373,19.319z"></path> <path display="none" d="M2.612,5.804V4.358c0,0,2.376-2.025,9.296-2.355l0.008,1.529
        C11.917,3.532,7.265,3.016,2.612,5.804"></path> <path display="none" d="M3.806,6.589c0,0-0.057,0.166,0.046,0.248c0.103,0.083,4.441,2.272,0.517,5.888
        c0,0-0.196,0.103,0.155,0.454c0.464,0.496,3.923,4.71,6.972,4.71c3.719,0,2.733-5.516,2.438-7.375
        c-0.261-1.635-0.733-6.311-4.982-5.633C4.69,5.56,4.054,6.341,3.806,6.589"></path> <path display="none" d="M5.56,12.19c-0.232-0.141-4.829-2.97-2.22-8.057c0,0,1.059-2.531,5.009-2.015
        c4.117,0.539,3.408,3.391,3.512,3.968c0.103,0.577-0.801,6.517,1.989,7.239l1.213,0.388v2.039c0,0-5.914-1.368-5.888-1.549
        c0.075-0.526,0.516-1.955-1.033-2.479C6.251,11.086,5.56,12.19,5.56,12.19"></path> <path display="none" d="M3.907,22.157c0,0,0.104-5.081-1.239-6.197s1.136-2.752,1.962-1.446s1.15-0.62,1.162-0.826
        c0.026-0.445,0.363-1.894,2.137-1.063c1.555,0.729,0.223,2.045-0.131,2.22c-0.585,0.29-1.72,0.868-1.773,1.529
        c-0.078,0.956-0.052,5.788-0.052,5.788L3.907,22.157z"></path> <path display="none" d="M9.175,14.844c0,0,0.084,3.257,2.737,3.492c1.162,0.103,2.065-0.388,3.15-1.938l-1.317-0.335
        c0,0-0.374,1.396-2.091,1.084c-1.162-0.211-1.136-1.885-1.136-1.885L9.175,14.844z"></path> <path display="none" d="M4.283,9.08c0,0-1.628-5.464,3.083-5.721c0,0,0.157,0.103-0.281,0.231
        c-1.886,0.595-2.66,1.938-2.737,5.062C4.336,9.084,4.284,8.982,4.283,9.08"></path> </svg>
    );
}

export default FootSvg;