import React from "react";
import database from "../../assets/data/database.json";

const RenderCovidNews = () => {
    const newslist = database.covidnews;

    return (
        newslist.map((news) => 
            <li>{news}</li>
        )
    );
};

const CovidNews = (props) => {
    return (  
        <React.Fragment>
            <div>
                <div className="banner__container">
                    <div className="banner__text--container">
                        <span className="banner__info--text long" style={{width:"400px"}}>Informationen</span>
                    </div>
                    <div className={"banner__parallax info__parallax"}>
                    </div>
                </div>
            </div>
            <div className="container underline my-5">
                <h2 className="mb-4">Wichtige Information</h2>
                <p>
                Sehr geehrte Patientinnen und Patienten!<br/>
                Covid-19: Unsere Praxis hat für im Normalbetrieb offen.
                Das Bestreben aller unserer Ärzte ist es, Ihre medizinische Versorgung zu gewährleisten, 
                Sie und unsere Mitarbeiter dabei aber auch vor einer Ansteckung durch SARS CoV 2 (Coronavirus, COVID-19) zu schützen.<br/> 
                Wir ersuchen um Verständnis, dass wir weiterhin Maßnahmen setzen müssen, um das Risiko für alle zu reduzieren:
                </p>     
                <ul>
                    <RenderCovidNews />
                </ul>
                <br />
            </div>
        </React.Fragment>
    );
    
};
export default CovidNews;