import React from "react";

function KneeSvg() {
    return (
        <svg viewBox="-7.074 -3.561 30 30" id="icon-knie"><title>knie</title>  <g> <g> <path className="color__icon" d="M13.309,3.98l-0.301,0.202c-0.278,0.188-6.838,4.61-8.117,6.517c-0.29,0.432-0.619,0.65-0.979,0.65
        c-0.453,0-0.914-0.337-1.169-0.625c-0.272-0.306-0.399-0.878-0.31-1.391C2.473,9.097,2.602,8.666,3,8.449
        c0.998-0.545,8.692-5.232,9.019-5.432l0.347-0.212L10.207,0L9.929,0.162C9.903,0.178,7.352,1.678,4.902,3.595
        C1.526,6.235-0.06,8.393,0.188,10.006c0.552,3.59,5.617,10.877,6.987,11.814l0.16,0.109l1.543-0.616l-0.301-0.383
        c-2.086-2.655-5.47-7.073-5.668-7.623c-0.093-0.259,0.025-0.485,0.104-0.597c0.166-0.236,0.451-0.399,0.675-0.366
        c0.089,0.013,0.169,0.018,0.241,0.018c0.529,0,0.749-0.292,0.895-0.485C4.934,11.732,5,11.646,5.199,11.605
        c0.252-0.052,0.696,0.135,0.925,0.457c0.132,0.185,0.161,0.374,0.09,0.578c-0.688,1.977,2.911,7.461,3.323,8.079l0.168,0.252
        l2.511-1.076l-0.123-0.326c-0.014-0.036-1.362-3.643-1.807-5.688c-0.377-1.735-1.814-2.815-2.487-3.237
        c0.287-0.173,1.003-0.49,2.757-0.885c3.764-0.847,4.668-2.484,4.706-2.554l0.103-0.193L13.309,3.98z M1.966,11.688
        c-0.405-0.157-1.069-1.038-0.907-1.848C1.22,9.03,1.934,8.544,1.869,9.063c0,0-0.161,1.316,0.193,1.736
        C2.716,11.575,2.307,11.82,1.966,11.688z"></path> </g> </g> <path display="none" d="M5.458,6.35c0,0,1.993,10.835-2.471,11.886c-2.27,0.534-3.362,0.813-2.872,2.203
c0.401,1.135,4.946,0.813,5.943,0.734c2.538-0.2,3.677-5.167,8.346-7.011c2.538-1.002,1.348-4.821-0.334-5.676
C11.809,7.448,11.546,3.769,11,2.877L5.458,6.35z"></path> <path display="none" d="M1.373,19.319c0,0-0.477-8.795-0.413-9.825c0.103-1.646,3.388-2.781,4.026,0
c0,0,0.407,2.072-1.755,2.662c-0.454,0.124,0.413,7.178,0.413,7.178L1.373,19.319z"></path> <path display="none" d="M2.612,5.804V4.358c0,0,2.376-2.025,9.296-2.355l0.008,1.529
C11.917,3.532,7.265,3.016,2.612,5.804"></path> <path display="none" d="M3.806,6.589c0,0-0.057,0.166,0.046,0.248c0.103,0.083,4.441,2.272,0.517,5.888
c0,0-0.196,0.103,0.155,0.454c0.464,0.496,3.923,4.71,6.972,4.71c3.719,0,2.733-5.516,2.438-7.375
c-0.261-1.635-0.733-6.311-4.982-5.633C4.69,5.56,4.054,6.341,3.806,6.589"></path> <path display="none" d="M3.907,22.157c0,0,0.104-5.081-1.239-6.197s1.136-2.752,1.962-1.446s1.15-0.62,1.162-0.826
c0.026-0.445,0.363-1.894,2.137-1.063c1.555,0.729,0.223,2.045-0.131,2.22c-0.585,0.29-1.72,0.868-1.773,1.529
c-0.078,0.956-0.052,5.788-0.052,5.788L3.907,22.157z"></path> <path display="none" d="M9.175,14.844c0,0,0.084,3.257,2.737,3.492c1.162,0.103,2.065-0.388,3.15-1.938l-1.317-0.335
c0,0-0.374,1.396-2.091,1.084c-1.162-0.211-1.136-1.885-1.136-1.885L9.175,14.844z"></path> <path display="none" d="M13.85,13.326c-2.79-0.723-1.886-6.662-1.989-7.239c-0.104-0.577,0.604-3.429-3.512-3.968
C4.398,1.603,3.34,4.134,3.34,4.134C0.731,9.221,5.328,12.05,5.56,12.19c0,0,0.691-1.104,2.583-0.466
c1.549,0.524,1.107,1.953,1.033,2.479c-0.026,0.181,5.888,1.549,5.888,1.549v-2.039L13.85,13.326z M7.084,3.591
c-1.886,0.595-2.66,1.938-2.737,5.062C4.336,9.084,4.284,8.982,4.283,9.08c0,0-1.628-5.464,3.083-5.721
C7.365,3.359,7.522,3.462,7.084,3.591z"></path> </svg>
    );
}

export default KneeSvg;