import React from "react";
import DoctorColumns from "./DoctorColumnComponent";

function ContentComponent(props) {
    return (
        <div className="">
            <DescriptionComponent field={props.field}/>
        </div>
    )
};


function DescriptionComponent(props) {
    const list = props.field.children.map(field =>{
        return(
            <div className={"physiotherapie__list--items"} key={field.id}>
                <h2 className={"physiotherapie__list--header"}>{field.name}</h2>
                <p>{field.description}</p>
            </div>
        )
    });



    return (
        <div className="container">
            <div className={"physiotherapie__list--items"} key={props.field.id}>
                <h2 className={"physiotherapie__list--header"}>{props.field.name}</h2>
                <p>{props.field.description}</p>
            </div>
            <div className={"physiotherapie__list--container"}>
                {list}
            </div>
        </div>
    )
};


function PhysioComponent(props) {
    return (
        <div className={"physio__background"}>

            <div className="banner__container">
                <div className="banner__text--container">
                    <span className="banner__info--text long">Physiotherapie</span>
                </div>
                <div className={"service__parallax carousel__img--item"}>
                </div>
            </div>

            <ContentComponent field={props.data.fields[1]}/>
            <div className="container">
                <DoctorColumns data={props.data} field={props.data.fields[1]}/>
            </div>
        </div>
    );
}


export default PhysioComponent;