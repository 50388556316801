import React from "react";
import DoctorColumns from "./DoctorColumnComponent";

function Radiologie(props) {

    return (
        <React.Fragment>
        <div className={"physio__background"}>

            <div className="banner__container">
                <div className="banner__text--container">
                    <span className="banner__info--text long">Radiologie</span>
                </div>
                <div className={"service__parallax carousel__img--item radiologie__parallax"}>
                </div>
            </div>
        </div>
        <div className="container">
            <h2>Radiologie - Privatpraxis</h2>
            <p>DR. MED. UNIV. STEPHAN SCHAMP-HERTLING<br/>
            Privatordination: Leopold-Schäftner-Gasse 6<br/>
            A-2380 Perchtoldsdorf - Bezirk Mödling<br/>
            </p>

        </div>
            <DoctorColumns data={props.data} field={props.data.fields[4]}/>
        </React.Fragment>
    );
}

export default Radiologie;