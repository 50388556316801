import React, {useState} from "react";
import {Link} from "react-router-dom";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';


function NavbarComponent() {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <div className="container-fluid navbar__main--container" id="nav">
            <header className="header" role="banner">
                <Navbar light expand="xl">
                    <NavbarBrand className="logo btmPadding" href="/"><img className={"home__logo"}
                                                                           src={require("../assets/thumbs/logo.jpg")}
                                                                           alt={"logo"}/>
                        <div className={"navigation__brand"}><span className={"color__primary"}>Orthozentrum.Wien</span>
                        </div>
                    </NavbarBrand>
                    <NavbarToggler onClick={toggle}/>
                    <Collapse isOpen={isOpen} navbar>
                        <div className="nav_right">
                            <Nav className="mr-auto" navbar>
                                <NavItem>
                                    <NavLink href="/">Home</NavLink>
                                </NavItem>
                                <UncontrolledDropdown nav inNavbar className={"nav-link"}>
                                    <DropdownToggle caret className="navButton" style={{fontWeight: "300"}}>
                                        Leistungen
                                    </DropdownToggle>

                                    <DropdownMenu>
                                        <div className={"navbar__dropdown--inner"}>
                                            <Link to={"/ortho"} onClick={toggle}> <DropdownItem>Orthopädie</DropdownItem></Link>
                                            <Link to={"/physiotherapie"} onClick={toggle}><DropdownItem>Physiotherapie</DropdownItem></Link>
                                            <Link to={"/stosswelle"} onClick={toggle}> <DropdownItem>Stosswellentherapie</DropdownItem></Link>
                                            <Link to={"/radiologie"} onClick={toggle}> <DropdownItem>Radiologie</DropdownItem></Link>
                                        </div>
                                    </DropdownMenu>

                                </UncontrolledDropdown>

                                <NavItem>
                                    <NavLink href="/team">Team</NavLink>
                                </NavItem>


                                <NavItem>
                                    <NavLink href="/diagnostic">Diagnosen</NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink href="/kontakt">Kontakt</NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                    </Collapse>
                </Navbar>
            </header>
        </div>
    );
}

export default NavbarComponent;
