import React from "react";
import FootSvg from "./icons/FootSvgComponent";
import HipSvg from "./icons/HipSvgComponent";
import KneeSvg from './icons/KneeSvgComponent';
import ShoulderSvg from "./icons/ShoulderSvgComponent";
import DoctorColumns from "./DoctorColumnComponent";

function OrthoComponent(props) {


    return (
        <React.Fragment>

            <div className={"physio__background"}>
                <div className="banner__container">
                    <div className="banner__text--container">
                        <span className="banner__info--text long">Orthopädie</span>
                    </div>
                    <div className={"service__parallax carousel__img--item"}>

                    </div>
                </div>
            </div>
     
            <div className="container underline">
                <h2 className="mb-4">Orthopädie</h2>
                <p>Die orthopädische Behandlung im orthozentrum.wien erfolgt durch erfahrene, spezialisierte Fachärzte und immer auf dem 
                    aktuellsten Wissensstand der Medizin. 
                    Unsere Ärzte gewährleisten Ihnen ein breites Spektrum an Behandlungsformen akuter und chronischer Erkrankungen und 
                    Verletzungen des Stütz- und Bewegungsapparates. Damit bieten wir in unserer 
                    orthopädischen Sprechstunde eine individuelle Beratung und Behandlung von Fachärzten, die sich auf Ihre Beschwerden spezialisiert hat.</p>
            <div className="row projects mt-4">
                    <div className="col-sm-6 item">
                        <div className="row">
                            <div className="col-lg-4 icon--width"><KneeSvg /></div>
                            <div className="col-lg-8">
                                <h3 className="name" ><strong>Knie</strong><br /></h3>
                                <ul>
                                    <li>Kreuzbandverletzungen</li>
                                    <li>Meniskusverletzungen</li>
                                    <li>Patella­luxation</li>
                                    <li>Seiten­band­verletzungen</li>
                                    <li>Knie­prothese</li>
                                    <li>Umstellungs­osteotomie</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 item">
                        <div className="row">
                            <div className="col-lg-4 icon--width"><FootSvg /></div>
                            <div className="col-lg-8">
                                <h3 className="name" ><strong>Fuss</strong><br /></h3>
                                <ul>
                                    <li>Hallux valgus</li>
                                    <li>Hallux rigidus</li>
                                    <li>Hammer­zehen, Krallen­zehen</li>
                                    <li>Metatarsalgie, Morton-Neurom, Stress­frakturen</li>
                                    <li>Achillessehne</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 item">
                        <div className="row">
                            <div className="col-lg-4 icon--width"><ShoulderSvg /></div>
                            <div className="col-lg-8">
                                <h3 className="name"><strong>Schulter</strong><br /></h3>
                                <ul>
                                    <li>Subacromial­syndrom​/​Impingement</li>
                                    <li>Rotatoren­manschetten­ruptur (RM)</li>
                                    <li>Acromio­clavicular­gelenk (AC)</li>
                                    <li>SLAP​-​Läsion</li>
                                    <li>Schulter­steife (Frozen Shoulder)</li>
                                    <li>Schulter­luxation</li>
                                    <li>Kalk­schulter</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 item">
                        <div className="row">
                            <div className="col-lg-4 icon--width"><HipSvg /></div>
                            <div className="col-lg-8">
                                <h3 className="name"><strong>Hüfte</strong><br /></h3>
                                <ul>
                                    <li>Coxarthrose</li>
                                    <li>FAI</li>
                                    <li>Knochen­mark­ödem­syndrom (KMÖS)</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                   
                </div>
                <DoctorColumns data={props.data} field={props.data.fields[0]}/>
            </div>
        </React.Fragment>

    );
}


export default OrthoComponent;