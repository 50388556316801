import React from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({text}) => (
    <div style={{
        color: 'white',
        backgroundImage: `url(${'/images/logoMap.png'})`,
        // background: 'black',
        backgroundSize: 'cover',
        padding: '9px 4px',
        display: 'inline-flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '100%',
        width: '18px',
        height: '18px'
        // transform: 'translate(-50%, -50%)'
    }}>
        {text}
    </div>
);

class SimpleMap extends React.Component {
    static defaultProps = {
        center: {lat: 48.172637, lng: 16.294704},
        zoom: 17
    };

    render() {
        return (
            <div style={{height: '100%', width: '100%'}}>
                <GoogleMapReact
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                >
                    <AnyReactComponent
                        lat={48.172637}
                        lng={16.294704}
                        // text={'ortho'}
                    />
                </GoogleMapReact>
            </div>
        );
    }
}

export default SimpleMap;