import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Map from './MapComponent'
import { Link } from "react-router-dom";
import { FaPhone } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { FaArrowRight } from 'react-icons/fa';

const CarouselComponent = (props) => {
    return (
        <Carousel className="carousel-fade" controls={true} indicators={true} interval={5000}>
            <Carousel.Item className="carousel__img--container">
                <div className="d-block w-100 carousel__img--item background__image--entry">
                    <Link to="/kontakt"  className="carousel--button">
                        <div className="carousel--button--text">
                            Kontaktieren Sie uns!
                        </div>
                    </Link>
                </div>
            </Carousel.Item>
            <Carousel.Item className="carousel__img--container">
                <div className="d-block w-100 carousel__img--item background__image--therapy">
                    <div className={"carousel__item--text"}>
                        <h2 className={"carousel__text--header text-white"}>Orthopädie</h2>
                        <p className={"carousel__text"}>
                            Präzise Diagnose und ausführliche Aufklärung<br /> des Patienten sind die Grundsteine<br /> für den bestmöglichen Behandlungserfolg
                        </p>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item className="carousel__img--container ">
                <div
                    className="d-block w-100 carousel__img--item background__image--physio">
                    <div className={"carousel__item--text"}>
                        <h2 className={"carousel__text--header text-white"}>Physiotherapie</h2>
                        <p className={"carousel__text"}>
                            Beratung, Trainingstherapie<br />
                            Sportphysiotherapie, Cranio–Sacral
                        </p>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item className="carousel__img--container">
                <div
                    className="d-block w-100 carousel__img--item background__image--stosswelle">
                    <div className={"carousel__item--text"}>
                        <h2 className={"carousel__text--header text-white"}>Stosswellentherapie</h2>
                        <p className={"carousel__text"}>
                            - Radiale Stosswellentherapie<br />
                        - Fokusierte Stosswellentherapie<br />
                        </p>
                    </div>
                </div>
            </Carousel.Item>
        </Carousel>
    );
};

const SpecializationComponent = (props) => {

    return (
        <React.Fragment>
            <div className="home__specialising">
                <div className="container-fluid">
                    <div className={"home__special--container"}>
                        <h1 className="center home__special--header mb-4">
                            Orthopädische Leistungen
                        </h1>
                        <div className="row">
                            <div className="equal">
                                <div className="home__special--item">
                                <h1 className="center mb-3">
                                    <Link to={{
                                        pathname: '/diagnostic',
                                        state: {
                                            active: 'knie'
                                        }
                                    }}>
                                        Knie
                                </Link>
                                </h1>
                                <p className="text-center">Rekonstruktive Knie­gelenks­chirurgie zum Erhalt bzw. zur Wiederherstellung der physiologischen Kniekinematik</p>
                                </div>
                            </div>
                                <div className="equal">
                                <div className="home__special--item">
                                <h1 className="center mb-3">
                                    <Link to={{
                                        pathname: '/diagnostic',
                                        state: {
                                            active: 'fuss'
                                        }
                                    }}>
                                        Fuss
                                </Link>
                                </h1>
                                    <p className="text-center">
                                        Für Fuß und Sprunggelenk das gesamte Spektrum der konservativen, funktionellen und operativen Therapie zur Verfügung
                                    </p>
                                </div>
                            </div>
                            <div className="equal">
                                <div className="home__special--item">
                                <h1 className="center mb-3">
                                    <Link to={{
                                        pathname: '/diagnostic',
                                        state: {
                                            active: 'schulter'
                                        }
                                    }}>
                                        Schulter
                                    </Link>
                                </h1>
                                <p className="text-center">Operative und konservative Behandlungen bei 
                                    Erkrankungen und Verletzungen der Schulter.</p>
                                </div>
                            </div>
                            <div className="equal">
                                <div className="home__special--item">
                                <h1 className="center mb-3">
                                    <Link to={{
                                        pathname: '/diagnostic',
                                        state: {
                                            active: 'huefte'
                                        }
                                    }}>
                                        Hüfte
                                </Link>
                                </h1>
                                <ul className="home__special--text">
                                    <li>
                                        Coxarthrose
                                    </li>
                                    <li className="mt-2">
                                        FAI
                                    </li>
                                    <li className="mt-2">
                                        Knochen­mark­ödem­syndrom (KMÖS)
                                    </li>
                                </ul>
                                </div>
                            </div>
                        </div>      
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

const AboutComponent = (props) => {
    return (
        <div>
            <div className="home__info underline text-center">
                <h2 className="home__header center mb-3">Unsere Ärzte und Therapeuten aus den Fachbereichen</h2>
                <div className="container">
                    <div className="row">
                        <div className="col-2"></div>
                        <div className="col-8 mt-4 text-center ">
                            <p className="letter-spacing font-weight-400 infoline">
                                Orthopädie | Sportorthopädie | orthopädische Chirurgie | Innere Medizin | Rheumatologie | Traumatologie | 
                                Osteopathie | Radiologie | Physiotherapie | Stosswellentherapie | Osteologie
                            </p>
                        </div>
                        <div className="col-2"></div>
                    </div>
                </div>
            </div>

        </div>
    );
};

const IntroComponent = () => {
    return (
        <React.Fragment>
            <div className="container mt-5 py-5 underline">
                <h2 className="">Willkommen</h2>
                <p>Das orthozentrum.wien vereint diverse Spezialisten unter einem Dach, diese arbeiten
                interdisziplinär und können so von Anfang der Verletzung bis zur Genesung des Patienten
                eine vollständige Betreuung im Orthozentrum gewährleisten bzw. ihn durch Schmerz-
                Bewegungstherapie begleiten.
                </p>
            </div>
        </React.Fragment>
    );
};

const InfoComponent = () => {
    return (
        <div className="container home__news">
            <div className="row">
                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="my-1"><FaArrowRight />&nbsp;Covid-19: Unsere Praxis hat für Sie im Normalbetrieb offen.</h5>
                            <p className="card-text mb-1">Bleiben Sie bei grippeähnlichen Beschwerden (Fieber, Husten, Gliederschmerzen) zuhause.</p>
                            <a href="/covidnews" className="btn btn-link">&#62;&nbsp;weitere Informationen</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="my-1"><FaArrowRight />&nbsp;Erste „Nanoskopie“ in Österreich</h5>
                            <p className="card-text mb-1">Wir freuen uns sehr, dass am 03.12.2020 die erste „Nanoskopie“ in Österreich
                             in Kooperation mit der Firma „Arthrex“ erfolgreich durchgeführt werden konnte.</p>
                            <a href="/" className="btn btn-link">&#62;&nbsp;weitere Informationen</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="my-1"><FaArrowRight />&nbsp;NEU! Stosswellentherapie</h5>
                            <p className="card-text mb-1">Stoßwellen sind energiereiche, hörbare Schallwellen.
                            In der Medizin werden Stoßwellen bereits seit 1980 u. a. zur Auflösung von Nierensteinen eingesetzt.</p>
                            <a href="/stosswelle" className="btn btn-link">&#62;&nbsp;weitere Informationen</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const MapComponent = (props) => {
    return (
        <div className={"home__map--container"}>
            <div className={"home__map--item map"}>
                <Map />
            </div>

            <div className={"home__map--item information__container mt-4"}>
                    <div className="home__map--inside underline">
                        <h2 className={"home__map--header"}>Orthozentrum Wien</h2>
                        <p>Zentrum für Orthopädie <br /> Münichreiterstrasse 29/3 <br /> 1130 Wien</p>
                    </div>
                    <div className="home__map--inside underline">
                        <h2 className={"home__map--header"}>Terminvereinbarung</h2>
                        <p><FaPhone className="info__icon" /> Tel: <a href="tel:+4318902808">+43 1 8902808</a></p>
                        <p><IoIosMail className="info__icon" /><a href="mailto:office@orthozentrum.wien">office@orthozentrum.wien</a></p>
                    </div>
                    <div className={"home__map--inside underline"}>
                        <h2 className={"home__map--header"}>Anfahrt</h2>
                        <p>Über <span className={"home__map--highlight"}>U4 Hietzing</span> mit <span
                            className={"home__map--highlight"}>58A/B</span> oder <span
                                className={"home__map--highlight"}>56A/B</span> an <span
                                    className={"home__map--highlight"}>Stranzenberggasse</span> oder <span
                                        className={"home__map--highlight"}>Montecuccoliplatz</span><br />
                            oder mit <span className={"home__map--highlight"}>62 Straßenbahn</span> an <span
                                className={"home__map--highlight"}>Atzgersdorfer Straße</span>
                        </p>
                    </div>            
            </div>
        </div>
    );
};

function Home(props) {
    return (
        <div>
            <CarouselComponent />
            <IntroComponent />
            <InfoComponent />
            <AboutComponent />
            <SpecializationComponent />
            <MapComponent />

        </div>
    );
}

export default Home;